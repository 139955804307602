.primary-nav {
  z-index: 1;
  position: relative;

  &__item  {
    @include fnt($display-serif-font, 24, 400, 30, 1.2);
    color: $grey;
    margin: .5em 0;
    position: relative;

    @include above($desktop-xl) {
      @include fntSize(30, 36);
      margin: 2vh 0;
    }

    &:first-child {
      margin-top: 0;
    }

    > a {
      position: relative;
      display: block;
    }

    > a:before,
    > a:after {
      content: '';
      position: absolute;
      width: 4px;
      top: 50%;
      right: 0;
      bottom: 50%;
      transition-property: top, bottom;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
    }

    > a:before {
      background-color: $brown;
      background-image: $gradient-brown;
      z-index: 1;
    }

    > a:after {
      background-color: rgba($white, .2);
      z-index: 0;
    }

    &:focus:not(.is-active),
    &:hover:not(.is-active) {
      > a:after {
        background-color: rgba($white, .2);
        background-image:none;
      }
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
      color: $white;
    }

    &:hover,
    &:focus,
    &:active {
      > a:after {
        top: 0;
        bottom: 0;
      }
    }

    &.is-active {
      > a:before {
        top: 0;
        bottom: 0;
      }
    }
  }
}


.locations + .btn {
  margin-top: rem(20)
}

.location {
  margin: rem(30) 0;

  &__title {
    @include fnt($display-sans-font, 14, 400, 18, 2.16);
    text-transform: uppercase;
    margin: 0 0 1em;
  }

  [data-location]:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.address-container {
  display: flex;

  .address {
    width: 50%;
    padding-right: 20px;
  }
}

.address {
  a {
    @include underlinedHover();
  }

  h5, p {
    margin: 0;
  }

  h5 {
    font-weight: 400;
    color: $brown;
  }
}

/// Since the current way to qualify a class from within its ruleset is quite
/// ugly, here is a mixin providing a friendly API to do so.
/// @author Hugo Giraudel
/// @param {String} $element-selector - Element selector
@mixin qualify($args...) {
  $selectors: ();

  @each $selector in $args {
    $selectors: append($selectors, unquote('#{$selector + &}'), 'comma')
  }

  @at-root #{$selectors} {
    @content;
  }
}

/// @alias qualify
@mixin when-is($args...) {
  @include qualify($args...) {
    @content;
  }
}


// .button {
//   border: none;
//
//   // Qualify `.button` with `button`
//   @include qualify(button) {
//     -webkit-appearance: none;
//   }
//
//   // Qualify `.button` with `a`
//   @include when-is(a) {
//     text-decoration: none;
//   }
// }

.site-footer__doormat {
  background-color: #131621;
}

.nav-doormat {
  @include fnt($display-sans-font, 12, 700, 12, 1.47);
  color: #7C838C;
  width: 100%;
  // height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  max-width: 800px;
  margin: auto;
  padding: rem(40) 0;

  &--mobile {
    padding: rem(20);
  }

  &__column {
    width: 25%;
    flex-grow: 1;
  }

  &__title {
    @include fnt($display-serif-font, 12, 700, 12, 1.47);
    text-transform: none;
    margin: 1em 0 .9em 0;
    color: $white;
  }
}


.nav-doormat__discover__item {
  margin: .5em auto;
  display: block;
}


.nav-doormat__main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

}

.nav-doormat__main__item {
  padding: 1em 0;
  width: 100%;
}



.nav-doormat--mobile {

  .nav-doormat__column {
    width: 33.333%;

    &:nth-child(1) {
      text-align: left;
      order: 1;
    }

    &:nth-child(2) {
      text-align: right;
      order: 3;
    }

    &:nth-child(3) {
      order: 2;
    }
  }
}


.maps__popup__title,
.maps__popup__address p {
  margin: 5px 0;
}

.maps__popup__title {
 font-size: rem(26);
 font-weight: 400;
 letter-spacing: .07em;
}

.maps__popup__address p {
  @include fntSize(14, 20, 0.2);

  a {
    @include underlinedHover();
  }
}


.controls{
  &__inner {
    display: flex;
    margin: 0 rem(5);
    padding-top: rem(7);
  }

  &__spacer {
    flex-grow: 1;
  }
}


[data-timeline-control] {
  @include center();
  position: relative;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  width: rem(40);
  height: rem(32);
  background-color: transparent;
  cursor: pointer;

}

[data-state="on"],
[data-state="off"] {
  transition: transform .3s ease-in-out;
  // &:hover {
  //   transform: scale(1.15);
  // }
}

[data-state="on"] {
  opacity: 1;
}

[data-state="off"] {
  opacity: .5;
}


[data-timeline-control="playback"] {
  overflow: hidden;

  .icn {
    &,&:before,&:after {
      transition: all .3s ease-in-out;
      position: absolute;
    }
  }

  &[data-state="playing"] {
    .icn--play {
      opacity: 0;
      transform: scale(.2);
    }

    .icn--pause {
      opacity: 1;

      &:before { margin-left: -4px; }
      &:after { margin-left: 4px; }
    }

    &:hover {
      .icn--pause {
        &:before { margin-left: -5px; }
        &:after { margin-left: 5px; }
      }
    }

  }


  &[data-state="paused"] {
    .icn--pause {
      opacity: 0;
      &:before,
      &:after {  }
      &:before { margin-left: -10px; }
      &:after { margin-left: 10px; }
    }

    .icn--play {
      opacity: 1;
      transform: scale(1);
    }

    // &:hover {
    //   .icn--play {
    //     opacity: 1;
    //     transform: scale(1.15);
    //   }
    // }
  }

}

.controls__legend {
  @include fntSize(12, null, 1.2);
  margin: 0;
  padding: 7px 0 0 5px;
  display: none;

  @include above($tablet) {
    display: block;
  }
}

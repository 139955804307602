// ==========================================================================
// Base
// Base rules are the defaults. They are almost exclusively single element
// selectors but it could include attribute selectors, pseudo-class selectors,
// child selectors or sibling selectors. Essentially, a base style says that
// wherever this element is on the page, it should look like this. ==========================================================================

body, .page {
  @include fnt($base-sans-font, 16, null, 22, null);
  color: $grey;
}

nav {
  ol,
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
    transition: color .3s ease-in-out;
  }
}


h1 {
  @include fnt-section-title();
  color: $brown;
}


.cookie-bar {
  @include fntSize(14, 18, 1.22);
  text-align: center;
  background: $grey;
  color: $white;
  padding: rem(10);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  p {
    margin: 0 rem(20);
  }

  .btn {
    font-size: rem(10);
    max-width: 100%;
    min-width: auto;
  }
}

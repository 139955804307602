// ==========================================================================
// Button Base
//
// ==========================================================================

button, a {
  &,&:focus,&:hover,&:active {
    outline: 0;
  }
}

.btn {
  @include fnt($base-sans-font, 12, 600, null, 2.5);
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  min-width: rem(170);
  padding: em(14, 12) em(18, 12);
  border: 0;
  border-radius: 2px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: $brown;
  background-color: transparent;
  transition: all .2s ease-in-out;
  cursor: pointer;

  &:hover {
    backgbackground-colorround: #ECECED;
  }

  @include when-is('button') {
    appearance: none;
  }
}



// ==========================================================================
// Button Show Brand
//
// ==========================================================================

.btn--show-brand {
  @include fnt($display-sans-font, 13, 400, null, 2.16);
  text-align: center;
  width: 100%;

  .icn {
    width: rem(7);
    height: rem(12);
    background-size: contain;
    float: right;
    transform: scaleX(-1);
  }

}


// ==========================================================================
// Button Shadowed
//
// ==========================================================================

.btn--shadowed {
  box-shadow: 0 0 10px 0 rgba(#000, .50);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 10px 0 rgba(#000, .40);
  }
}


// ==========================================================================
// Button Outlined
//
// ==========================================================================


.btn--outlined {
  border: 1px solid currentColor;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  transition-property: background, color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;

  span {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to right, #DDA168,#695441);
    opacity: 0;
    transition-property: opacity;
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: $white;
    background-color: #695441;

    &:before {
      opacity: 1;
    }
  }
}

.brand__cta .btn--outlined {
  background-color: rgba($white, .7);
}


// @supports(--css: variables) {
//   .btn--outlined {

//     &:before {
//       --size: 0;
//       left: var(--x);
//       top: var(--y);
//       width: var(--size);
//       height: var(--size);
//       background-image: radial-gradient(circle closest-side, #695441, transparent);
//       transform: translate(-50%, -50%);
//       transition: width .2s ease, height .2s ease;
//     }

//     &:hover {
//       background: #DDA168;
//     }

//     &:hover:before {
//       --size: 400px;
//     }
//   }
// }


// ==========================================================================
// Button Outlined White
//
// ==========================================================================
.btn--outlined-white {
  color: $white;
  border: 1px solid currentColor;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  transition-property: background, color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;

  span {
    position: relative;
  }


  &:hover {
    background-color: rgba($white, .3);
  }

}


// ==========================================================================
// Button Transparent
//
// ==========================================================================

.btn--transparent {
  @include fnt($display-sans-font, 16, 600, null, 1.83);
  padding: em(11, 16) em(18, 16);
  background-color: transparent;
  color: $white;
  border: 1px solid currentColor;

  &:hover {
    background-color: rgba($white, .2);
  }
}


// ==========================================================================
// Back Arrow
//
// ==========================================================================

.btn--back-arrow {
  position: relative;
  width: rem(40);
  height: rem(40);
  min-width: auto;
  padding: 0;
  overflow: hidden;
  background-image: url('../img/ui/arrow-back.svg');
  background-repeat: no-repeat;
  background-position: center;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
  }
}

@mixin constrain() {
  height: 100%;

  @include above(840px) {
    max-width: 700px;
  }

  @include above(940px) {
    max-width: 800px;
  }

  @include above(1040px) {
    max-width: 900px;
  }
}

.chapter-content {
  position: absolute;
  color: white;
  height: calc(100% - #{$subtitles-height-mobile});
  width: 100%;
  z-index: 1;
  padding: rem(10);

  @include above($tablet) {
    @include center();
    padding: rem(20);
    height: 100%;
  }

  &__inner {
    width: 100%;
    height: 100%;

    // @include above($tablet) {
    //   height: auto;
    // }
  }
}

.chapter-template {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  margin: auto;
}


.secondary-visual {
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba($black, .2);

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.history-visual {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba($black, .2);

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
}

.history-visual.full {
  height: 100%;
  .history-visual__inner {
    background-size: cover;
  }
}

.history-fact {
  @include fnt($display-serif-font, 14, 400, 18, null);

  @include above($tablet) {
    @include fntSize(18,26)
  }

  &__title,
  &__content {
    margin: 0;
  }
}

// ==========================================================================
// TWO ROWS TEMPLATE
// ==========================================================================

.chapter-template--two-rows {
  @include constrain();
  flex-direction: column;
  flex-wrap: nowrap;


  .full-width-column {
    height: 50%;
    width: 100%;
    position: relative;
    padding: 0;

    @include above($tablet) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      flex-grow: 1;
      flex-direction: row;
      height: 46%;
      padding: 10px 0;
    }
  }

  .full-width-column.two-columns {
    @include below($tablet - 1px) {
      display: flex;
      // flex-direction: column-reverse;
      flex-direction: column;
      height: 66.666%;
    }
  }

  .full-width-column + .full-width-column {
    @include below($tablet - 1px) {
      margin-top: 10px;
    }
  }

  .full-width-column.two-columns + .full-width-column {
    @include below($tablet - 1px) {
      height: 33.333%;
    }
  }

  .history-visual {
    flex-grow: 1;

    @include below($tablet - 1px) {
      height: 100%;
    }
  }



  .history-fact {
    @include center();
    flex-wrap: wrap;
    background-color: rgba($black, .58);
    text-align: center;
    overflow: hidden;

    @include above($tablet) {
      margin-top: 0;
      margin-right: 20px;
    }

    &__inner {
      padding: rem(10);

      @include above($tablet) {
        width: 360px;
      }
    }

    &__title {
      @include fntSize(25, 25, null);
      position: relative;
      margin: 0 0 rem(10);
      padding: 0 0 rem(15);

      @include above($tablet) {
        @include fntSize(65, 65, null);
        margin: 0 0 rem(20);
        padding: 0 0 rem(25);
      }

      &:after {
        @include ornament('white');
      }
    }

    &__content {

      @include above($tablet) {
        min-width: 200px;
        margin: auto;
      }

    }
  }

  .full-width-column.two-columns {
    @include below($tablet - 1px) {
      .history-visual {
        height: 50%;
      }

      .secondary-visual,
      .history-fact {
        height: 50%;
        // margin-top: 0;
        // margin-bottom: 20px;
      }
    }
  }

  .history-visual + .secondary-visual,
  .history-visual + .history-fact {
    margin-top: 10px;

    @include above($tablet) {
      margin-right: 0;
      margin-top: 0;
      margin-left: 20px;
    }
  }
}






// ==========================================================================
// OVERLAYED TEMPLATE
// ==========================================================================

.chapter-template--overlayed {
  @include constrain();
  position: relative;


  @include above($tablet) {
    align-items: center;
  }

  .full-width-column {
    width: 100%;
    position: relative;

    @include below($tablet - 1px) {
      height: 100%;
    }

    @include above($tablet) {
      height: auto;
    }
  }

  .history-visual  {
    position: relative;

    @include below($tablet - 1px) {
      height: 33.333%;
    }

    @include above($tablet) {
      @include aspect-ratio(1006, 672);
      height: auto;
      flex-grow: 1;
    }

    &.full {
      @include below($tablet - 1px) {
        height: 100%;
      }
    }
  }



  .history-fact-container {
    height: 66.666%;
    display: flex;
    flex-direction: column;

    @include below($tablet - 1px) {
      margin: 0
    }

    @include above($tablet) {
      flex-grow: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      bottom: -50%;
      width: 30%;
      max-width: 350px;
      z-index: 1;
      display: block;
      height: auto;
    }

    .history-fact {
      @include below($tablet - 1px) {
        height: 50%;
      }
    }

  }

  .history-fact {
    padding: rem(10);

    @include below($tablet - 1px) {
      margin: 10px 0 0 0
    }

    @include above($tablet) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      padding: rem(60);
    }

    &,&:before {
      background-color: rgba($black, .9);
    }

    @include above($tablet) {
      &:before {
        content: '';
        display: block;
        left: 0;
        right: 0;
        bottom: 100%;
        top: -200%;
        position: absolute;
      }
    }

    &__title {
      @include fnt($base-sans-font, 16, 400, 20, null);

      @include above($tablet) {
        @include fntSize(24, 30, null)
      }
    }
  }

  .history-fact-visual {
    @include aspect-ratio(915, 335);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 20px 0;
    position: relative;
    overflow: hidden;

    @include below($tablet - 1px) {
      height: 50%;
      margin: 10px 0 0 0
    }

    @include above($tablet) {
      margin: 0;
      // background-color: rgba($black, .9);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      padding: 0;
    }

    &__inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

  }

  .history-visual + .history-fact-container {
    left: auto;
    right: 0;
  }
}


.chapters {
  height: 100%;
  position: relative;
  z-index: 2;

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // background-image: url('../img/history/bg-generation1.jpg');
    // background-position: center;
    // background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    transition: background 1s;



    img {
      position: relative;
      display: block;
      z-index: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($black, .59);
      z-index: 1;
    }
  }
}

.history-item.chapter-content,
.history-item.chapter-intro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - #{$subtitles-height-mobile});
  z-index: 1;

  @include above($tablet) {
    height: 100%;
  }

  // &.hide {
  //   z-index: 0;
  // }
}

.intro__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 1s ease-out;

  &.intro-id-1 {
    background-image: url('../img/history/bg-generation1.jpg');
  }

  &.intro-id-2 {
    background-image: url('../img/history/bg-generation2.jpg');
  }

  &.intro-id-3 {
    background-image: url('../img/history/bg-generation3.jpg');
  }

  &.intro-id-4 {
    background-image: url('../img/history/bg-generation4.jpg');
  }

  &.intro-id-5 {
    background-image: url('../img/history/bg-generation5.jpg');
  }

  &.intro-id-6 {
    background-image: url('../img/history/bg-generation6.jpg');
  }

  &.intro-id-7 {
    background-image: url('../img/history/bg-generation7.jpg');
  }

  &.intro-id-8 {
    background-image: url('../img/history/bg-generation8.jpg');
  }

  &.show {
    opacity: 1;
  }
}

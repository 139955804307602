
.article {
  min-height: 100vh;
  background-image: linear-gradient(137deg, #E8EDF1 0%, #B9BCC6 100%);
  padding-bottom: rem(30);

  &__inner {
    max-width: rem(740);
    padding: rem(20);
    margin: auto;
    margin-bottom: rem(30);
  }

  &__ui {
    @include clearfix();

    .btn--back {
      float: right;
      padding-right: 0;
      min-width: auto;
    }
  }

  &__header {
    height: rem(280);
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      background: rgba(#000, .5);
    }
  }

  &__title {
    @include fnt($display-serif-font, 30, 400, 33, 1.2);
    margin-bottom: rem(30);
    text-transform: none;
    color: $white;
    z-index: 1;
    max-width: 330px;
    position: relative;
    text-align: center;
  }

  &__intro {
    @include fnt-intro-center();
    max-width: 450px;
    margin: rem(30) auto;

  }

  &__intro,
  &__content {
    position: relative;
    padding-bottom: rem(30);

    &:after {
      @include ornament();
    }
  }
}

.age-check {

  max-width: 100%;
  min-height: 292px;
  background-color: #6E7F91;
  background-image: linear-gradient(145deg, #6E7F91 0%, #3F4D5C 100%);
  box-shadow: 0 4px 6px rgb(0,0,0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  text-transform: uppercase;
  padding: 30px;
  margin: rem(20);
  text-align: center;

  @include above($mobile) {
    padding: 40px;
    width: 550px;
    text-align: left;
  }

  &__inner {
    position: relative;
    padding-left: 0;

    @include above($mobile) {
      padding-left: 100px;
    }

    @include above($tablet-xs) {
      padding-left: 160px;
    }

    &:after {

      @include above($mobile) {
        content: '';
        width: 60px;
        height: 60px;
        display: block;
        background-image: url('../img/ui/bottlecap.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: -50px;
        margin-top: -5px;
        width: 120px;
        height: 110px;
        margin-top: -20px;
        left: 0;
      }

      @include above($tablet-xs) {
        width: 181px;
        height: 169px;
        margin-top: -40px;
      }
    }
  }

  &__title {
    @include fnt($display-sans-font, 24, 700, 30, 1.2);
    color: inherit;
    margin: 0;

    @include above($tablet-xs) {
      @include fntSize(30, 36, 1.2);
    }
  }

  &__content {
    @include fnt($display-serif-font, 22, 400, 26);
    color: inherit;
    margin: 0;

    @include above($tablet-xs) {
      @include fntSize(37, 32);
    }
  }

  &__cta {
    margin-top: 30px;
    text-align: center;
    position: relative;
    z-index: 100;

    @include above($mobile) {
      text-align: left;
    }

    .btn {
      min-width: 100%;
      cursor: pointer;

      @include above($mobile) {
        min-width: 100px;
      }

      @include below($tablet-xs) {
        &:hover {
          background: transparent;
        }
      }

    }

    .btn + .btn {
      margin: 15px 0 0 0;
      @include above($mobile) {
        margin: 0 0 0 15px;
      }
    }
  }

  &--visible {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s ease-out;
  }

  &--hide {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
  }

  &__lang {
    width: 100%;
    text-align: center;
     padding-bottom: 20px;
     padding-top: 10px;

    @include above($mobile) {
      margin-top: -35px;
      padding-bottom: 40px;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      list-style: none;
      font-family: $display-serif-font;
      font-weight: bold;
      color: $dark-blue;
      cursor: pointer;

      &.active {
        color: $white;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }

      &:after {
        content: "|";
        display: inline-block;
        padding: 0 12px;
        color: $dark-blue;
      }
    }
  }
}

.article-nav {
  text-align: center;

  li {
    @include fnt($display-serif-font, 18, 400, 21);
    color: $brown;
    display: inline-block;
    cursor: pointer;
  }
}

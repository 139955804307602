// ==========================================================================
// Mixins
// ==========================================================================

@mixin active-dot() {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    top: 50%;
    margin-top: -2px;
    left: 0;
    opacity: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $brown;
    background-image: $gradient-brown;
    transition: all .3s ease-in-out;
  }
}

@mixin active-dot-active() {
  &:before {
    left: -15px;
    opacity: 1;
  }
}

@mixin aspect-ratio($width, $height, $child: null) {
  position: relative;

  &:before {
    display: block;
    padding-top: (($height / $width) * 100%);
    width: 100%;
    content: ' ';
  }
  @if($child) {
    & > #{$child} {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

@mixin center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin ornament($clr: 'brown') {
  content: '';
  width: rem(26);
  height: rem(7);
  background-image: url('../img/ui/article_ornament_' + $clr + '.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@mixin titleDividerLine() {
  content: '';
  display: block;
  width: 100px;
  border-bottom: 1px solid rgba(#974B02, .25);
  position: absolute;
  bottom: 0;

  @content;
}

@mixin fntSize($fs, $lh: null, $ls: null) {
  font-size: rem($fs);
  line-height: if($lh, ($lh/$fs), null);
  letter-spacing: if($ls, em($ls, $fs), null);
}

@mixin fnt($font-family, $fs, $fw:400, $lh: null, $ls: null) {
  font-family: $font-family;
  font-weight: $fw;
  @include fntSize($fs, $lh, $ls);
}

@mixin fnt-default-text() {
  @include fnt(null, 12, 400, 16, 0.2);
  color: #7C838C;
}

@mixin fnt-section-title() {
  @include fnt($display-sans-font, 24, 400, 24, 3.7);
}

@mixin fnt-intro-center() {
  @include fnt($display-serif-font, 18, 500, 21, null);
  color: $brown;
  text-align: center;
}


@mixin fnt-specs() {
  @include fnt($display-sans-font, 14, 700, 24, 1.2);
  color: $black;
}


@mixin fnt-subtitles() {
  @include fnt($base-sans-font, 14, 400, 16, 0.4);
  color: $white;
  text-shadow: 1px 1px 0 #010102;

  @include above($tablet) {
    @include fntSize(24, 28, 0.4);
  }
}


@mixin underlinedHover($color: inherit) {
  color: $color;
  position: relative;
  display: inline-block;
  padding-bottom: 3px;
  text-decoration: none;

  &,&:after {
    transition-property: opacity, left, right;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
  }

  &:after {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    background-color: currentColor;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
  }

  &:hover {
    &:after {
      left: 0;
      right: 0;
      opacity: 1;
    }
  }
}

@mixin keyframes( $animationName ) {
  @-webkit-keyframes #{$animationName} {
      @content;
  }

  @-moz-keyframes #{$animationName} {
      @content;
  }

  @-o-keyframes #{$animationName} {
      @content;
  }

  @keyframes #{$animationName} {
      @content;
  }
}

.article__content {
  @include fnt(null, 14, null, 22, 0.23);
  color: #06080B;

  h2, h3, h4, h5, h6 {
    font-family: $display-sans-font;
    color: #7C838C;
    font-weight: 400;
    margin: rem(20) 0 0 0;
  }

  h2 {
     @include fnt(null, 22, null, 30, 2.16);
  }

  h3 {
     @include fnt(null, 18, null, 26, 2.16);
  }

  h4, h5, h6 {
    @include fnt(null, 14, null, 24, 2.16);

  }

  ul, ol, p {
    margin: rem(30) 0;
  }

  ol,
  ul {
    padding: 0 0 0 1.4em;
  }

  img {
    width: 100%;
    height: auto;
  }
}

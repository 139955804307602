.brands-mobile-detail {
  padding: rem(20) rem(20) 0;
  max-width: 500px;
  margin: auto;
}

.brand-mobile {
  max-width: 400px;
    margin: auto;
    padding: 0 rem(20);


  p {
    margin: 0;
  }

  &__logo {
    img {
      max-width: 150px;
    }
  }

  &__inner {
    text-align: center;
    box-shadow: 0 0 10px 0 rgba($black, .50);

    background-color: $white;
  }

  &__header {
    @include fnt($display-serif-font, 14, 400, 16, null);
    color: $brown;
    padding: rem(20);
  }

  &__visual {
    position: relative;
    overflow: hidden;

    img {
      display: block;
      width: 120%;
      height: auto;
      margin-left: -14%;
      margin-right: -6%;
      margin-bottom: -14%;
    }
  }

  &__cta {
    @include fntSize(13, 14.6, 0.22);
    width: 100%;
    padding: rem(20);
    text-decoration: none;
    color: #7C838C;
    display: flex;
    align-items: center;

    span {
      padding: 0 rem(20);
    }

    .icn {
      width: rem(7);
      height: rem(12);
      background-size: contain;
      float: right;
      transform: scaleX(-1);
    }
  }
}


.brand-mobile-btn {
  padding: 0 rem(20);
  max-width: 400px;
  margin: auto;
}

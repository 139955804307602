
.btn-ctrl {
  @include fnt($display-sans-font, 14, 300, 14, 2.16);
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  background: $white;
  padding: 0;
  border: 0;
  min-width: 55px;
  height: 55px;
  box-shadow: 0 0 10px 0 rgba(#000, .50);
  cursor: pointer;

  &__inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  &__text {
    display: inline-block;
    overflow: hidden;
    position: relative;
    max-width: 0;
    opacity: 0;
    transition-property: padding, max-width, opacity;
    transition-duration: .3s;
    transition-delay: .3s, .3s, 0s;
    transition-timing-function: ease-in-out;
  }

  &__icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    width: 45px;
    height: 55px;
  }

  &:hover {

    .btn-ctrl__text {
      max-width: 200px;
      opacity: 1;
      transition-delay: 0s, 0s, .3s;
    }

  }

  @include when-is('button') {
    appearance: none;
  }
}


.btn-ctrl--next,
.btn-ctrl--prev {
  color: $brown;

  .btn-ctrl__text {
    display: none;

    @include above($tablet) {
      display: block;
    }
  }
}

.btn-ctrl--prev {

  .btn-ctrl__icon {
    background-image: url('../img/ui/arrow.svg');
  }

  &:hover {
    .btn-ctrl__text {
      padding-right: 20px;
    }
  }


}

.btn-ctrl--next {

  .btn-ctrl__inner {
    justify-content: flex-end;
  }

  .btn-ctrl__icon {
    background-image: url('../img/ui/arrow.svg');
    transform: scaleX(-1);
  }

  &:hover {
    .btn-ctrl__text {
      padding-left: 20px;
    }
  }

}



.btn-ctrl--view {
  color: #7C838C;
  opacity: .5;
  transition-property: opacity;
  transition-duration: .3s;
  transition-delay: .6s;
  transition-timing-function: ease-in-out;
  z-index: 99;

  .btn-ctrl__icon {
    width: 55px;
    background-image: url('../img/ui/view.svg');
    background-position: 11px 11px;
  }

  &:hover {
    transition-delay: .3s;
    opacity: 1;

    .btn-ctrl__text {
      padding-left: 10px;
      padding-right: 24px;
    }
  }
}


.btn-ctrl--view-clear {
  color: #7C838C;
  z-index: 99;
  background: transparent;
  box-shadow: none;

  .btn-ctrl__icon {
    width: 18px;
    height: 18px;
    background-image: url('../img/ui/view-white.svg');
    background-size: contain;
    background-position: center center;
  }

  .btn-ctrl__text {
    padding-left: 1em;
    padding-right: 24px;
    max-width: 100%;
    width: auto;
    opacity: 1;
    color: $white;
    font-size: rem(13);
    line-height: rem(18);
  }
}

$slide-transition-duration: 1s;
$slide-transition-delay-in: 1s;
$slide-transition-delay-out: 1.5s;


.history-item {
  transition-property: opacity;
  transition-duration: $slide-transition-duration;
  transition-timing-function: ease-in-out;

  &.show {
    transition-delay: $slide-transition-delay-in;
    opacity: 1;
  }

  &.hide {
    transition-delay: 0s;
    opacity: 0;
  }
}


@include above($tablet) {

  .history-item {

    .full-width-column.two-columns .secondary-visual,
    .full-width-column.two-columns .secondary-visual__inner,
    .full-width-column.two-columns .history-visual,
    .full-width-column.two-columns .history-visual__inner,
    .full-width-column.two-columns .history-fact,
    .chapter-template--two-rows .full-width-column,
    .chapter-template--overlayed .history-fact,
    .chapter-template--overlayed .history-fact-visual__inner,
    .chapter-template--overlayed .history-visual__inner,
    .chapter-intro__title,
    .chapter-intro__meta {
      animation-timing-function: ease-out;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    &.show {

      .chapter-intro__meta {
        position: relative;
        animation-name: BOUNCE-FROM-TOP;
        animation-duration: 1.5s;
        animation-delay: $slide-transition-delay-in + 0.1s;
      }

      .chapter-intro__title {
        position: relative;
        animation-name: BOUNCE-FROM-TOP;
        animation-duration: 1.5s;
        animation-delay: $slide-transition-delay-in;
      }

      .secondary-visual__inner,
      .history-visual__inner {
        animation-duration: 15s;
        animation-delay: $slide-transition-delay-in;
      }

      .chapter-template--overlayed .history-fact-visual__inner {
        opacity: 0;
        animation-name: SLIDE-FROM-BOTTOM;
        animation-duration: 3s;
        animation-delay: $slide-transition-delay-in * 4;
      }

      .chapter-template--overlayed .history-fact {
        opacity: 0;
        animation-name: SLIDE-FROM-TOP;
        animation-duration: 2s;
        animation-delay: $slide-transition-delay-in * 3;
      }

      .chapter-template--two-rows .full-width-column:nth-child(1) {
        height: 100%;
        width: 100%;
        flex-grow: 0;
        animation-name: SHRINK;
        animation-duration: 5s;
        animation-delay: $slide-transition-delay-in;
      }

      .chapter-template--two-rows .full-width-column:nth-child(2) {
        height: 0;
        width: 100%;
        opacity: 0;
        animation-name: GROW;
        animation-duration: 10s;
        animation-delay: $slide-transition-delay-in;
      }

      .full-width-column.two-columns .history-visual {
        flex-grow: 1;
      }

      .full-width-column.two-columns .secondary-visual,
      .full-width-column.two-columns .history-fact {
        width: 0;
        margin: 0;
        animation-name: FACT;
        animation-duration: 2s;
        animation-delay: $slide-transition-delay-in + 10s;
      }

      .full-width-column.two-columns:nth-child(2) .secondary-visual {
        width: 0;
        margin: 0;
        animation-name: FACT-LEFT;
        animation-duration: 2s;
        animation-delay: $slide-transition-delay-in + 10s;
      }

      &#chapter-id-1-1 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
          animation-duration: 18s;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-RIGHT;
        }
      }

      &#chapter-id-1-2 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-RIGHT;
          animation-duration: 18s;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
        }
      }

      &#chapter-id-2-1 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
          animation-duration: 15s;
          background-size: auto 100%;
        }
      }

      &#chapter-id-3-1 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-RIGHT;
          animation-duration: 29s;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
        }
      }

      &#chapter-id-4-1 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT, PAN-IMAGE-TO-BOTTOM;
          animation-duration: 16s;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-RIGHT;
        }
      }

      &#chapter-id-4-2 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-RIGHT;
          animation-duration: 16s;
        }

        .full-width-column:nth-child(1) .history-visual__inner {
          background-position: center 40%;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
          background-position: center 20%;
        }
      }

      &#chapter-id-5-1 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
          animation-duration: 37s;
          background-position: center 10%;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-RIGHT;
          background-position: center 30%;
        }
      }

      &#chapter-id-6-1 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-RIGHT;
          animation-duration: 17s;
        }


        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
          background-position: center 15%;
        }
      }

      &#chapter-id-6-2 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
          animation-duration: 18s;
        }

        .full-width-column:nth-child(1) .history-visual__inner {
          background-position: center top;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-RIGHT;
        }
      }

      &#chapter-id-7-1 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-RIGHT;
          animation-duration: 8s;
        }

        .chapter-template--two-rows .full-width-column:nth-child(1) {
          animation-duration: 3s;
        }

        .chapter-template--two-rows .full-width-column:nth-child(2) {
          animation-duration: 3s;
          animation-delay: 3s;
        }

        .full-width-column.two-columns .history-fact {
          animation-delay: 3s;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
        }
      }

      &#chapter-id-7-2 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
          animation-duration: 7s;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
        }
      }

      &#chapter-id-8-1 {
        .history-visual__inner {
          animation-name: KEN-FING-BURNS-RIGHT;
          animation-duration: 21s;
        }

        .full-width-column:nth-child(1) .history-visual__inner {
          background-position: center 10%;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          animation-name: KEN-FING-BURNS-LEFT;
        }

        .full-width-column:nth-child(2) .secondary-visual__inner {
          background-position: center top;
        }

        .chapter-template--two-rows .history-fact__title {
          font-size: rem(50);
        }


      }
    }

    &.hide {
      .chapter-intro__meta {
        animation-name: BOUNCE-TO-TOP;
        animation-duration: 1.5s;
        animation-delay: 0s;
      }

      .chapter-intro__title {
        animation-name: BOUNCE-TO-TOP;
        animation-duration: 1.5s;
        animation-delay: .1s;
      }


      .history-visual__inner {
        transform: scale(1) translate(0, 0);
      }

      .chapter-template--two-rows .full-width-column:nth-child(1) {
        height: 50%;
      }

      .chapter-template--two-rows .full-width-column:nth-child(2) {
        height: 50%;
      }

      .full-width-column.two-columns .secondary-visual,
      .full-width-column.two-columns .history-fact {
        width: 360px;
        margin-left: 20px;
      }

       &#chapter-id-2-1 {
        .history-visual__inner {
          background-size: auto 100%;
        }
      }

      &#chapter-id-4-1 {
        .history-visual__inner {
          background-position: 50% 40%;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          background-position: center center;
        }
      }


      &#chapter-id-4-2 {
        .full-width-column:nth-child(1) .history-visual__inner {
          background-position: center 40%;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          background-position: center 20%;
        }
      }

      &#chapter-id-5-1 {
        .history-visual__inner {
          background-position: center 10%;
        }

        .full-width-column:nth-child(2) .history-visual__inner {
          background-position: center 30%;
        }
      }

      &#chapter-id-6-1 {
        .full-width-column:nth-child(2) .history-visual__inner {
          background-position: center 15%;
        }
      }

      &#chapter-id-6-2 {
        .full-width-column:nth-child(1) .history-visual__inner {
          background-position: center top;
        }
      }

      &#chapter-id-8-1 {
        .full-width-column:nth-child(1) .history-visual__inner {
          background-position: center 10%;
        }

        .full-width-column:nth-child(2) .secondary-visual {
          margin-left: 0;
          margin-right: 20px;
        }

        .full-width-column:nth-child(2) .secondary-visual__inner {
          background-position: center top;
        }

        .chapter-template--two-rows .history-fact__title {
          font-size: rem(50);
        }
      }

    }
  }
}

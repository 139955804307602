.table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;

  th, td {
    padding: 0;
  }
}

.table--specs {
  th, td {
    border-bottom: 1px solid rgba(#974B02, .25);
  }

  th {
    @include fnt-specs();
    text-align: left;
  }

  td {
    @include fnt-intro-center();
    text-align: right;
  }

  caption {
    @include fnt-intro-center();
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

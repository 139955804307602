$subtitles-height: 80px;
$subtitles-height-mobile: 40px;
$controls-height: 40px;
$timeline-height: 80px;
$timeline-height-mobile: 50px;
$timeline-padding: 7px;
$timeline-padding-mobile: 4px;
$timeline-full-height: ($timeline-height + ($timeline-padding * 2));
$timeline-full-height-mobile: ($timeline-height-mobile + ($timeline-padding-mobile * 2));
$min-height: 650px;


.history {
  position: relative;
  overflow: hidden;
  height: 100vh;

  @include above($tablet) {
    height: 100vh;
    min-height: ($min-height + $timeline-full-height + $controls-height);
  }


  &__chapters {
    position: relative;
    z-index: 1;
    height: calc(100vh - #{$timeline-full-height-mobile + $controls-height + 87px});

    @include above($tablet) {
      height: calc(100vh - #{$timeline-full-height + $controls-height});
      min-height: $min-height;
    }

  }

  &__bottom {
    position: relative;
    z-index: 1;
  }

  &__controls {
    height: $controls-height;
    background: #080B0E;
  }

  &__timeline {
    // @include center();
    height: $timeline-full-height-mobile;
    background: #06080A;
    position: relative;

    @include above($tablet) {
      height: $timeline-full-height;
    }
  }
}






.sub-nav {
  display: none;

  @include above($desktop-sm) {
    text-align: left;
    width: 50%;
    margin: 2vh 0 0 50%;
  }

  .is-active & {
    display: inline-block;
  }

  &__item {
    @include fnt($display-sans-font, 14, 400, 36, 2.16);
    color: #7C838C;
    position: relative;

    a {
      @include active-dot();
    }

    &.is-active a {
      @include active-dot-active();
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
      color: $white;
    }
  }
}

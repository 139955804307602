$tile-width: 320px;
$tile-margin: 10px;

.brands-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  width: 100%;

  @include above($tablet + 1px) {
    max-width: ($tile-width + ($tile-margin * 4)) * 2;
  }
}

.brands-selector__item {
  position: relative;
  max-width: $tile-width;
  width: calc(50% - #{($tile-margin * 2)});
  margin: ($tile-margin * 2) $tile-margin;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba($black, .5);

  @include above($tablet + 1px) {
    box-shadow: none;
    margin: ($tile-margin * 2);
    width: calc(50% - #{($tile-margin * 4)});
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
    float: left;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}



.brands-selector__item__cta {
  background-color: $white;
  display: none;

  @include between(360px, $tablet) {
    display: block;
  }
}


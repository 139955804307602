.section--brands {
  background-color: #7E8D98;
  background-image: linear-gradient(144deg, #7E8D98 0%, #4A4D58 100%);


  .btn-ctrl--view {
    position: absolute;
    top: auto;
    bottom: 30px;
    left: 30px;
  }
}


.subtitles {
  @include fnt-subtitles();
  @include center();
  position: absolute;
  background-color: rgba($black, .48);
  min-height: $subtitles-height-mobile;
  padding: rem(10);
  width: 100%;
  z-index: 2;
  text-align: center;
  bottom: 0;
  transform: translateY(100%);
  transition: transform .3s ease-in-out;
  transition-delay: .3s;

  @include above($tablet) {
    min-height: $subtitles-height;
  }

  p {
    margin: 0;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    transition-delay: 0s;
  }

  &.is-shown {
    transform: translateY(0);
    transition-delay: 0s;

    p {
      opacity: 1;
      transition-delay: .3s;
    }
  }
}

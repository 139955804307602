.scrollbar {
  @include above($desktop-sm) {
    width: rem(4);
    background: $black;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;

    // &__handle {
    //   position: absolute;
    //   width: 100%;
    //   min-height: rem(44);
    //   background-color: $brown;
    //   background-image: $gradient-brown;
    // }
  }
}

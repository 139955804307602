.visor {
  $width: 63px;
  $width-mobile: 39px;
  $offset: 2px;
  $offset-mobile: 1px;

  width: $width-mobile;
  height: $timeline-height-mobile + ($offset-mobile * 2);
  position: absolute;
  top: -($offset-mobile);
  bottom: -($offset-mobile);
  z-index: 2;
  background-image: url('../img/ui/timeline-visor.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-50%);

  @include above($tablet) {
    width: $width;
    top: -($offset);
    bottom: -($offset);
    height: $timeline-height + ($offset * 2);
  }

  &:after,
  &:before {
    content: '';
    display: block;
    width: 100vw;
    height: $timeline-height-mobile;
    position: absolute;
    background-color: rgba($black, .35);
    top: $offset-mobile;

    @include above($tablet) {
      height: $timeline-height;
      top: $offset;
    }
  }

  &:before {
    right: 0;
    margin-right: $width-mobile;

    @include above($tablet) {
      margin-right: $width;
    }
  }

  &:after {
    left: 100%;
  }
}


@keyframes FADE-IN {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



@keyframes FADE-OUT {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@keyframes PAN-IMAGE-TO-BOTTOM {
  0% {
    background-position: 50% 50%;
  }

  50% {
    background-position: 50% 40%;
  }

  100% {
    background-position: 50% 40%;
  }
}


@keyframes KEN-FING-BURNS-LEFT {
  0% {
    transform: scale(1.2) translate(-5%, 2%);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

@keyframes KEN-FING-BURNS-RIGHT {
  0% {
    transform: scale(1.2) translate(5%, -2%);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

@keyframes FACT {
  0% {
    width: 0;
    margin-left: 0;
  }

  100% {
    width: 360px;
    margin-left: 20px;
  }
}

@keyframes FACT-LEFT{
  0% {
    width: 0;
    margin-right: 0;
  }

  100% {
    width: 360px;
    margin-RIGHT: 20px;
  }
}

@keyframes SHRINK {
  0% {
    height: 100%;
  }

  60% {
    height: 100%;
  }

  100% {
    height: 50%;
  }

}

@keyframes GROW {
  0% {
    height: 0;
    opacity: 0;
  }

  30% {
    height: 0;
  }

  70% {
    height: 50%;
    opacity: 0;
  }

  100% {
    height: 50%;
    opacity: 1;
  }

}



@keyframes BOUNCE-FROM-TOP {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  60% {
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}


@keyframes BOUNCE-TO-TOP {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  40% {
    transform: translateY(10px);
  }

  100% {
    opacity: 0;
    transform: translateY(-50px);
  }

}

@keyframes SLIDE-FROM-TOP {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes SLIDE-TO-TOP {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}


@keyframes SLIDE-FROM-BOTTOM {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes SLIDE-TO-BOTTOM {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

// ==========================================================================
// Brewery general
//
// ==========================================================================

.section--brewery {

  padding: rem(25);
  background-color: #bbc0c6;
  position: relative;
  overflow: hidden;

  @include above($tablet) {
    padding: rem(70);
  }

  @include above($desktop-sm) {
    min-height: 100vh;
  }

  &:before {
    content: "";
    background: radial-gradient(circle closest-side, #e0e2e5, transparent);
    position: absolute;
    z-index: 0;
    left: -350px;
    top: -350px;

    width: 800px;
    height: 800px;

    pointer-events: none;
  }

  .section__inner {
    position: relative;
    z-index: 1;
  }
}

.brewery {
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &--first {
      .brewery__col {
        margin-top: 0;

        @include above($tablet-xs) {
          margin-top: rem(30px);
        }

        &.brewery__intro {
          margin-top: 0;

          @include below($desktop-md) {
            width: 100%;
          }
        }
      }
    }

    &--spacing {
      padding: rem(20px) 0;
      position: relative;

      @include above($tablet) {
        padding: rem(40px) 0;
      }
    }

    .btn {
      @include fnt($display-sans-font, 14, 600, null, 1.83);
    }
  }

  &__col {
    h2 {
      @include fnt($display-sans-font, 24, 600, null, 2);
      text-transform: uppercase;
      color: $brown;
      position: relative;
      margin-bottom: rem(30px);
      z-index: 2;

      &:after {
        content: "";
        background-color: $white;
        width: 8px;
        height: 2px;

        position: absolute;
        left: 0;
        bottom: -15px;
      }

    }

    p {
      @include fnt($base-sans-font, 14, 400, null, null);
      color: $dark-gray;
      text-align: justify;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__col-3 {
    width: 100%;

    @include above($tablet) {
      width: calc(50% - 40px);
    }

    @include above($desktop-md) {
      width: calc(33.33% - 50px);
    }
  }

  &__col-7 {
    width: 100%;

    @include above($desktop-md) {
      width: calc(68% - 50px);
    }
  }

  &__intro {

    h1 {
      @include fnt($display-serif-font, 40, 400, null, 1);
      color: $dark-gray;

      position: relative;
      margin-bottom: rem(50px);
      margin-top: 0;

      @include above($tablet-xs) {
        @include fnt($display-serif-font, 50, 400, null, 1);
      }

      &:after {
        content: "";
        background-image: url('../img/ui/title-divider.svg');
        background-repeat: no-repeat;
        background-size: contain;

        width: 22px;
        height: 8px;

        position: absolute;
        left: 0;
        bottom: -30px;
      }
    }

    p {
      @include fnt($display-serif-font, 20, 400, null, 1);
      color: $dark-gray;
      text-align: left;
    }
  }
}

.brewery__partnership,
.brewery__brands-block {
  h2 {
    @include above($tablet) {
      display: flex;
      align-items: flex-end;
      min-height: 66px;
    }
  }
}

// ==========================================================================
// Brewery Animations
//
// ==========================================================================

.brewery {
  &__animation {
    h1,
    h2,
    p,
    &.brewery__worldwide,
    .btn__wrapper {
      transform: translateY(40px);
      opacity: 0;

      transition: all 1s cubic-bezier(.28,.69,.23,1);
      transition-delay: .6s;
    }

    p {
      transition-delay: .9s;
    }

    .btn__wrapper {
      transition-delay: 1.2s;
    }

    &.brewery__products .slide.tns-slide-active {

      h2,
      .product__description,
      .product__options li {
        transform: translateY(20px);
        opacity: 0;

        transition: all 1s cubic-bezier(.28,.69,.23,1);
        transition-delay: .6s;
      }

      .product__description {
        transition-delay: .9s;
      }

      .product__options {
        li {
          &:nth-child(1) {
            transition-delay: .9s;
          }
          &:nth-child(2) {
            transition-delay: 1s;
          }
          &:nth-child(3) {
            transition-delay: 1.10s;
          }
          &:nth-child(4) {
            transition-delay: 1.20s;
          }
          &:nth-child(5) {
            transition-delay: 1.30s;
          }
          &:nth-child(6) {
            transition-delay: 1.40s;
          }
        }
      }
    }

    &.brewery__partnership {
      h2 {
        transition-delay: .9s;
      }

      p {
        transition-delay: 1.2s;
      }
    }

    &.brewery__brands-block {
      h2 {
        transition-delay: 1.5s;
      }

      p {
        transition-delay: 1.8s;
      }
    }

    &.brewery__worldwide {
      h2 {
        transform: none;
        opacity: 1;
      }
    }

    &.brewery__kettle {
      h3,
      img {
        transform: translateY(40px);
        opacity: 0;

        transition: all 1s cubic-bezier(.28,.69,.23,1);
        transition-delay: .9s;
      }

      img {
        transition-delay: 1.2s;
      }
    }

    &.has-entered {
      h1,
      h2,
      p,
      &.brewery__worldwide,
      h3,
      &.brewery__products .slide.tns-slide-active h2,
      &.brewery__products .slide.tns-slide-active .product__description,
      &.brewery__products .slide.tns-slide-active .product__options li,
      .btn__wrapper,
      &.brewery__kettle img {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}


// ==========================================================================
// Brewery worldwide
//
// ==========================================================================

.brewery__row-worldwide {
  transform: scale(.7);


  @include above($tablet-xs) {
    transform: scale(1);
  }

  @include above($desktop-md) {
    margin-top: -20%;
  }

  @include above($hd) {
    margin-top: -10%;
  }

  @include above($desktop-xxl) {
    margin-top: 0;
  }
}

.brewery {
  &__worldwide {

    margin-left: 20%;
    padding: 80px 0;
    position: relative;
    min-width: 252px;

    @include above($tablet-xs) {
      margin-left: 35%;
    }

    h2 {
      line-height: rem(32px);
      position: relative;
      text-shadow: 38px 65px 10px rgba(150, 150, 150, 0.3);

      &:after {
        content: none;
      }
    }

    .brewery__singleLine {
      background-color: #fff;
      width: 100%;
      height: 2px;
      position: absolute;
      left: 4px;
      bottom: 90px;
      z-index: -2;
    }

    .waypoint__animation {
      position: absolute;
      top: 0;
      height: 1px;
    }

    .brewery__number {
      @include fnt($display-sans-font, 120, 400, null, 1);
      color: $white;

      position: absolute;
      left: -80px;
      top: 0;
    }

    .brewery__title {
      @include fnt($display-sans-font, 40, 600, null, 1);
      color: $dark-brown;
    }

    .brewery__subtitle {
      @include fnt($display-serif-font, 30, 500, null, 1);
    }

    .brewery__images {

      > div {
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .brewery__cap {
      background-image: url('../img/brewery/cap.png');

      width: 500px;
      height: 410px;
      z-index: -1;

      top: -8%;
      right: -125%;
    }

    .brewery__map {
      background-image: url('../img/brewery/map.png');

      width: 500px;
      height: 500px;
      z-index: -5;

      top: -30%;
      right: -130%;
    }

    .brewery__lines {
      background-image: url('../img/brewery/lines.png');

      width: 460px;
      height: 200px;
      z-index: 0;

      top: -6%;
      right: -118%;
    }

    .brewery__splashes {
      background-image: url('../img/brewery/splashes.png');

      width: 390px;
      height: 420px;
      z-index: -1;

      top: -8%;
      right: -30%;
    }

  }
}


// ==========================================================================
// Brewery kettle
//
// ==========================================================================


.brewery {
  &__kettle {
    order: 1;
    width: 100%;
    margin-left: 0;
    margin-top: rem(60px);

    @include above($tablet) {
      order: 0;
      width: calc(50% - 40px);
      margin-left: 0;
      margin-top: 0;
    }

    h2 {
      margin-left: rem(200px);
      line-height: rem(20px);

      &:after {
        width: 100%;
        background-color: $brown;
      }
    }

    h3 {
      margin-top: 0;
      margin-left: rem(200px);
      position: absolute;
    }

    .brewery__number {
      @include fnt($display-sans-font, 220, 400, null, 1);
      @extend %textgradient-vertical--brown;
      text-shadow: 48px 35px 10px rgba(150, 150, 150, 0.3);

      position: absolute;
      left: rem(-90px);
      top: rem(-40px);
      line-height: rem(200px);
    }

    .brewery__plus {
        @include fnt($display-sans-font, 110, 400, null, 1);
        @extend %textgradient-vertical--brown;
        text-shadow: 48px 35px 10px rgba(150, 150, 150, 0.3);
        left: rem(-150px);
        top: rem(-40px);
        line-height: rem(200px);
        position: absolute;
        display: inline-block;
      }

    .brewery__title {
      @include fnt($display-serif-font, 70, 300, null, 1);
      color: $white;
      text-transform: none;
    }

    .brewery__subtitle {
      @include fnt($display-sans-font, 20, 400, null, 1);
      color: $white;
      text-transform: uppercase;
      z-index: 1;
    }

    img {
      width: auto;
      height: auto;
      margin-top: rem(-210px);
      z-index: -4;
      position: relative;
    }
  }

  &__brewing {
    margin-top: rem(40px);
    order: 0;

    @include above($tablet) {
      order: 0;
    }
  }
}

// ==========================================================================
// Brewery Packaging
//
// ==========================================================================


.brewery__packaging {
  margin-top: rem(30px);

  @include below($desktop-md) {
    width: 100%;
  }
}


// ==========================================================================
// Brewery Products
//
// ==========================================================================


.brewery {
  &__products {
    background-color: $white;
    min-height: 420px;
    margin-top: rem(40px);

    @include above($desktop-md) {
      margin-top: 0;
    }

    .only-slide {
      .product__inner {
        padding: 0;
      }

      .product__image {
        top: rem(-60px);
      }
    }

    .products__slider-wrapper {
      position: relative;

      @include above($tablet-xs) {
        position: static;
      }
    }

    .tns-inner {
      min-height: 480px;
    }

    .slider-controls {

      @extend %clear;

      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
      width: 100%;
      pointer-events: none;

      @include above($tablet-xs) {
        position: static;
        transform: none;
        padding: 0 20px 20px;
      }

      button {
        outline: none;
      }

      .btn-ctrl--next, .btn-ctrl--prev {
        pointer-events: auto;

        @include above($tablet-xs) {
          float: left;
        }
      }

      .btn-ctrl--next {
        float: right;
      }

      .btn-ctrl__icon {
        background-size: 50%;
        width: 30px;
        height: 35px;

      }

      .btn-ctrl {
        min-width: 35px;
        height: 35px;

        background: transparent;
        box-shadow: none;
      }
    }

    .slide {

      h2,
      .product__description,
      .product__options li {
        transform: translateY(20px);
        opacity: 0;

        transition: all 1s cubic-bezier(.28,.69,.23,1);
        transition-delay: .3s;
      }

      .product__description {
        transition-delay: .6s;
      }

      .product__options {
        li {
          &:nth-child(1) {
            transition-delay: .9s;
          }
          &:nth-child(2) {
            transition-delay: 1s;
          }
          &:nth-child(3) {
            transition-delay: 1.10s;
          }
          &:nth-child(4) {
            transition-delay: 1.20s;
          }
          &:nth-child(5) {
            transition-delay: 1.30s;
          }
          &:nth-child(6) {
            transition-delay: 1.40s;
          }
        }
      }


      &.tns-slide-active {

        h2,
        .product__description,
        .product__options li {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }

    .product {
      &__inner {

        width: 100%;
        position: relative;
        padding-top: rem(15px);
        padding-right: rem(15px);

        @include above($tablet-xs) {
          width: 50%;
          padding-top: 0;
          padding-right: 0;
        }

        h2 {
          @include fnt($display-serif-font, 36, 400, 36, 1);
          text-transform: none;
          margin-top: 0;
          margin-bottom: rem(10px);

          &.has-amount {
            padding-right: rem(105px);
          }
        }

        h3 {
          @include fnt($display-serif-font, 24, 400, null, 1);
          color: $grey;
          margin-top: 0;
        }
      }

      &__options {
        ul {
          margin: 0;
          padding: 0;

          li {
            list-style: none;
            border-bottom: 1px solid rgba($grey, .2);

            @include fnt($base-sans-font, 14, 600, 20, 1);
            padding: rem(10px) 0;

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }

      &__amount {
        @include fnt($display-serif-font, 24, 400, null, 1);
        color: $grey;

        position: absolute;
        right: 0;
        top: 8px;
      }

      &__image {
        width: 90%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        img {
          max-width: 150px;
        }

        @include above($tablet-xs) {
          position: absolute;
          right: -100%;
          top: rem(-40px);
          width: 100%;


          img {
            max-width: 260px;
          }
        }
      }
    }

    .slide {

      @include below($tablet-xs) {
        padding: rem(30px) rem(30px) rem(30px) rem(40px) !important;
      }

      @include above($tablet-xs) {
        padding: rem(50px) rem(30px) rem(30px);
      }

      &:nth-child(1) {
        .product__inner {
          .product__image {
            @include above($tablet-xs) {
              img {
                max-width: 500px;
              }
            }
          }
        }
      }

      &:nth-child(2) {
        .product__inner {
          .product__image {
            @include above($tablet-xs) {
              img {
                max-width: 300px;
              }
            }
          }
        }
      }

      &:nth-child(3) {
        .product__inner {
          .product__image {
            @include above($tablet-xs) {
              top: rem(50px);

              img {
                max-width: 300px;
              }
            }
          }
        }
      }

      &:nth-child(5) {
        .product__inner {
          .product__image {
            @include above($tablet-xs) {
              top: rem(10px);

              img {
                max-width: 280px;
              }
            }
          }
        }
      }

    }

    .tns-outer {
      margin-top: -20px;

      @include above($tablet-xs) {
        margin-top: -30px;
      }
    }

    .slider-controls--hide {
      display: none;
    }
  }
}


// ==========================================================================
// Brewery Export
//
// ==========================================================================


.brewery {
  &__export {
    width: 100%;
    padding: rem(80px) 0;

    @include above($tablet) {
      padding: 0;
    }

    &.has-entered {
      .brewery__truck {
        left: 10px;
      }
    }

    &-inner {
      position: relative;
      margin-top: rem(-80px);

      @include above($tablet) {
        margin-left: 35%;
        margin-top: 0;
      }
    }

    h2 {

      padding-left: rem(100px);
      margin-bottom: 0;

      @include above($tablet-xs) {
        padding-left: rem(150px);
      }

      @include above($tablet) {
        margin-bottom: rem(30px);
      }

      &:after {
        width: calc( 100% - 210px);
        left: rem(100px);
        bottom: rem(32px);
        background-color: $dark-brown;

        @include above($tablet-xs) {
          width: calc( 100% - 306px);
          left: rem(152px);
          bottom: rem(40px);
        }
      }

      .brewery__number {
        @include fnt($display-sans-font, 100, 400, null, 1);
        @extend %textgradient-vertical--brown;

        position: absolute;
        left: 0;
        top: 0;
        line-height: rem(115px);

        @include above($tablet-xs) {
          @include fnt($display-sans-font, 150, 400, null, 1);
        }
      }

      .brewery__title {
        @include fnt($display-serif-font, 50, 300, null, 1);
        color: $white;

        line-height: rem(60px);
        display: block;

        @include above($tablet-xs) {
          @include fnt($display-serif-font, 70, 300, null, 1);
        }
      }

      .brewery__subtitle {
        @include fnt($display-sans-font, 16, 400, null, 1);
        color: $white;

        display: block;
        margin-top: rem(16px);

        @include above($tablet-xs) {
          @include fnt($display-sans-font, 20, 400, null, 1);
          margin-top: rem(28px);
        }
      }
    }

    h3 {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;


      .brewery__number {
        @include fnt($display-sans-font, 40, 600, null, 1);
        @extend %textgradient-vertical--brown;

        display: block;
        margin-left: rem(-30px);
        line-height: rem(45px);

        @include above($tablet-xs) {
          @include fnt($display-sans-font, 50, 600, null, 1);
          margin-left: rem(-40px);
          line-height: rem(48px);
        }
      }

      .brewery__title {
        @include fnt($display-serif-font, 24, 300, null, 1);
        color: $white;

        @include above($tablet-xs) {
          @include fnt($display-serif-font, 34, 300, null, 1);
        }
      }
    }

    .btn__wrapper {
      text-align: right;
    }
  }

  &__ground {
    background-image: url('../img/brewery/ground.png');
    background-repeat: no-repeat;
    background-size: contain;

    width: 200%;
    height: 120px;

    position: absolute;
    z-index: -2;
    left: -60px;
    bottom: -100px;

    @include above($tablet-xs) {
      width: 100%;
      height: 120px;

      left: -10px;
      bottom: -30px;
    }
  }

  &__clouds {
    background-image: url('../img/brewery/clouds.png');
    background-repeat: no-repeat;
    background-size: contain;

    width: 850px;
    height: 170px;

    position: absolute;
    z-index: -2;
    left: -100px;
    top: 90px;

    @include above($tablet-xs) {
      width: 1200px;
      height: 240px;

      left: -100px;
      top: -100px;
    }
  }

  &__truck {
    background-image: url('../img/brewery/truck.png');
    background-repeat: no-repeat;
    background-size: contain;

    width: 180px;
    height: 70px;

    position: absolute;
    left: -50%;
    bottom: 0;

    @include above($tablet-xs) {
      width: 380px;
      height: 140px;

      bottom: 40px;
    }

    transition: left 1.2s cubic-bezier(.28,.69,.23,1);
    transition-delay: .4s;
  }
}

// ==========================================================================
// Brewery talk
//
// ==========================================================================

.brewery {
  &__talk {
    text-align: center;
    margin: 0 auto;

    p {
      @include fnt($display-serif-font, 20, 400, null, 1);
      color: $dark-gray;
      max-width: 720px;
      text-align: center;
    }

    .btn {
      @include fnt($display-sans-font, 16, 600, null, 1.83);
    }
  }
}

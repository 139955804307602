$brand-tile-size: 320px;

.brand-tile {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-color: $white;
  /*transition-property: background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;*/
  user-select: none;

  &__box-shadow {

    @include above($tablet + 1px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 2px 2px 5px rgba($black, .5);
    }

  }

  &__visual,
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__visual {
    z-index: 0;
    top: -9.0625%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity .3s ease-in-out;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content{
    z-index: 1;
    color: $white;
    text-align: center;
    transition: transform .3s ease-in-out;
  }


  &__logo {
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    margin: 0;
    padding: 0 0 5% 0;
    width: 70%;

    @include above($tablet) {
      width: auto;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 150px;
      height: 2px;
      transform: translateX(-50%);
      background-color: $brown;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 200px;

      @include above($tablet) {
        width: auto;
      }

    }
  }

  &__title,
  &__tagline {
    text-shadow: 0 0 14px rgba($black, .7);
  }

  &__title {
    @include fnt($display-serif-font, 32, 300, 32, null);
    margin: 0;
  }

  &__tagline {
    @include fnt($display-sans-font, 14, 600, 14, null);
    margin: .8em 0 0;
    text-transform: uppercase;
  }


  &__header {
    position: absolute;
    padding-top: 18px;
    top: 178px;
    left: 0;
    right: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -75px;
      width: 150px;
      height: 2px;
      background-color: $brown;
    }
  }
}

.brand-tile--1758 {
  .brand-tile__logo img {
    width: 60%;

    @include above($tablet + 1px) {
      width: auto;
    }
  }
}


/*@include above($tablet + 1px) {
  .brand-tile:hover {
    transform: scale(.95);

    .brand-tile__content {
      transform: scale(1.05);
    }
  }
}*/




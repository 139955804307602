.article-teaser--highlighted {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  min-height: 340px;
  order: 2;

  .section--press-full & {
    flex-grow: 0;
  }


  @include above($tablet) {
    order: 1;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background: rgba(#000, .5);
  }

  .article-teaser__inner {
    position: relative;
    z-index: 1;
    display: inline-block;
    text-align: center;
    color: $white;

    @include above($tablet) {
      max-width: rem(400);
    }
  }


  .article-teaser__title {
    @include fnt($display-serif-font, 22, 400, 33, 0.88);
    text-transform: none;
    margin: 0;
    padding: 0 2em;

    @include above($tablet) {
      @include fntSize(30, 33, 1.2);
      margin-bottom: rem(30);
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .article-teaser__title,
  .article-teaser__title a,
  .btn {
    color: inherit;
  }
}



// $slide-transition-duration: 1s;
// $slide-transition-delay-in: 1s;
// $slide-transition-delay-out: 1.5s;


@include below($tablet - 1px) {

  #chapter-id-2-1 {
    .chapter-template--overlayed {
       .history-visual.full .history-visual__inner {
         background-size: contain;
         background-position: center top;
       }
    }
  }

  #chapter-id-4-2 {
    .chapter-template--two-rows {
      .history-fact-container,
      .history-visual {
        height: 50%;
      }

      .full-width-column:nth-child(2) .history-visual,
      .history-fact-visual {
        height: 100%;
      }

      .history-visual,
      .history-fact-visual {

        &__inner {
          background-position: center top;
        }
      }



      .full-width-column:nth-child(2) .history-visual__inner {
        background-position: center 10%;
      }
    }
  }

  #chapter-id-5-1 {
    .history-visual__inner {
      background-position: center 10%;
    }

    .full-width-column:nth-child(2) .history-visual__inner {
      background-position: center 30%;
    }
  }

   #chapter-id-6-1 {
    .full-width-column:nth-child(2) .history-visual__inner {
      background-position: center 15%;
    }
  }

  #chapter-id-6-2 {
    .full-width-column:nth-child(1) .history-visual__inner {
      background-position: center top;
    }
  }

  #chapter-id-8-1 {
    .chapter-template--two-rows {
      .full-width-column:nth-child(1),
      .full-width-column:nth-child(2) {
        height: 50%;
      }
      .full-width-column:nth-child(1) .history-visual__inner {
        background-position: center top;
      }
      .full-width-column:nth-child(2) .secondary-visual {
        margin-bottom: 10px;
      }

    }
  }

}

.form .btn {
  margin-top: rem(30);
}

.fieldset {
  border: 0;
  padding: 0;
  box-shadow: 5px 10px 20px rgba(#000, .05);
  background-color: $white;
}

.field {
  position: relative;
  border: 3px solid #F0F0F0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -2px;
    top: 50%;
    bottom: 50%;
    width: 2px;
    transition-property: top, bottom;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
  }


  & + & {
    border-top: 0;
  }

  label {
    @include fntSize(12, 16, 0.2);
    padding: rem(10) rem(10) 0;
  }

  textarea {
    resize: none;
  }

  label,
  textarea,
  input[type="text"],
  input[type="email"] , {
    display: block;

  }

  textarea,
  input[type="text"],
  input[type="email"] {
    @include fntSize(16, 16, 0.23);
    color: $black;
    font-weight: 600;
    appearance: none;
    border: 0;
    background-color: transparent;
    width: 100%;
    padding: 0;
    padding: rem(5) rem(10);
  }




  &--text {
    height: rem(60);

    label {

    }

    input {
      height: auto;
    }
  }

  &--textarea {
    height: 95px;

    label {
      height: rem(25);
    }

    input {
      height: rem(90);
    }
  }
}



.field {
  &.focused,
  &.error {
    &:before {
      top: -2px;
      bottom: -2px;
    }
  }

  &.focused {
    &:before {
      background: $gradient-brown;
    }
  }

  &.error {
    &:before {
      background: $error-color;
    }
  }
}

.chapter-intro {
  @include center();
  position: absolute;
  color: white;
  // height: calc(100% - #{$subtitles-height-mobile});
  z-index: 1;
  text-align: center;
  padding: rem(10);
  background: #000;

  @include above($tablet) {
    padding: rem(40) 0 rem(120) 0;
    height: 100%;
  }

  // &__inner {
  //   transform: translateY(-($subtitles-height/2));
  //   text-align: center;
  // }

  &__title {
    $spacing: 4.58;
    @include fnt(null, 20, 400, 22, $spacing);
    text-transform: uppercase;
    max-width: 700px;
    @include above($desktop) {
      @include fntSize(30, 34, $spacing);
    }

    @include above($desktop-xl) {
      @include fntSize(36, 40, $spacing);
    }
  }

  &__meta__date {
    $spacing: 10;
    @include fnt($display-serif-font, 36, 600, 45, $spacing);

    @include above($desktop) {
      @include fntSize(80, 80, $spacing);
    }

    @include above($desktop-xl) {
      @include fntSize(100, 100, $spacing);
    }
  }

  &__meta__title {
    $spacing: 10;
    @include fnt($display-sans-font, 36, 700, 43, $spacing);
    text-transform: uppercase;
    // max-width: em(266, 36);
    margin: auto;

    @include above($desktop) {
      @include fntSize(80, 95, $spacing);
    }

    @include above($desktop-xl) {
      @include fntSize(100, 95, $spacing);
    }
  }

  &__meta + &__title {
    margin-top: em(60, 100);
  }

}


.site-footer {
  color: $white;

  a {
    @include underlinedHover();
  }

  &__legal {
    @include fnt($base-serif-font, 12, 700, 12, 1.47);
    text-align: center;
    background-color: #7C838C;
    padding: 25px 0;
    

    .copyright,
    .nav-legal__item {
      display: block;
      padding: .5em 0;
    }

    .nav-legal {
      padding-right: 14px;
      margin-right: 13px;
      border-right: 1px solid #FFF;
      padding-bottom: 4px;
      display: inline-block;
      vertical-align: middle;
    }

    @include above($mobile) {
      .copyright,
      .nav-legal,
      .nav-legal__item {
        display: inline-block;
        padding: 0;
      }

      .copyright {
        padding-right: 14px;
        margin-right: 13px;
        border-right: 1px solid currentColor;
      }

      .nav-legal__item + .nav-legal__item {
        padding-left: 14px;
        margin-left: 13px;
        border-left: 1px solid currentColor;
      }

      .copyright {
        padding-bottom: 4px;
      }

      .nav-legal {
        padding-right: 14px;
        margin-right: 13px;
        border-right: 1px solid #FFF;
        padding-bottom: 4px;
        display: inline-block;
        vertical-align: middle;
      }
    }

  }


  span.radikal {
    
    display: inline-block;
    vertical-align: middle;

    a {
      transition: opacity 0.3s;
    }

    a:hover {
      opacity: 0.7;

      &:after {
        display: none;
      }
    }

    img {
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
    }
  }

}

.section--intro {
  position: relative;
  overflow: hidden;
  padding: 0;
  min-height: auto;

  @include above($desktop-sm) {
    min-height: 100vh;
  }

  video {
    width: 100%;
    height: auto;
    border: none;
    display: block;

    margin-left: -1px;

    @include above($desktop-sm) {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      min-width: 101%;
      min-height: 100%;
      width: auto;
      height: auto;

      overflow: hidden;
    }
  }
}

.intro {
  &__controllers {
    width: 100%;
    min-height: 100%;

    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
  }

  &__replay {
    opacity: 1;
    transform: scale(1) rotate(0deg);

    width: 40px;
    height: 40px;

    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 18%;

    background-image: url('../img/ui/replay.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;

    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);

    @include above($tablet-xs) {
      width: 60px;
      height: 60px;

      bottom: 14%;
    }

    &:hover {
      @include above($tablet) {
        opacity: .6;
        transform: scale(.8) rotate(360deg);
      }
    }

    &--hide {
      opacity: 0;
      transform: scale(0) rotate(360deg);
      pointer-events: none;
    }
  }

  &__discover {
    opacity: 1;
    color: $light-brown;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    position: absolute;
    z-index: 5;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 22px;

    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: .4s;

    @include above($tablet-xs) {
      bottom: 35px;
    }

    &--hide {
      opacity: 0;
      bottom: -60px;
      pointer-events: none;
    }

    &.arrow-down {
      &:after {
        content: "";
        width: 15px;
        height: 10px;
        margin: 5px auto;

        position: absolute;
        left: 0;
        right: 0;
        bottom: -16px;

        background-image: url('../img/ui/arrow-down.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

        @include above($tablet-xs) {
          width: 22px;
          height: 15px;
          bottom: -30px;
          margin: 10px auto;
        }
      }
    }

    &:hover {
      letter-spacing: 5px;
      transition-delay: 0s;
      &.arrow-down {
        &:after {
          bottom: -35px;
        }
      }
    }
  }

  &__play-pause {
    display: inline-block;
  }

  &__video-ended {
    .intro__play-pause {
      display: none;
    }
  }

  &__play {
    opacity: 1;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 25px;
    margin: auto;
    text-align: center;

    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);

    span {
      display: inline-block;
      vertical-align: middle;
      padding-top: 5px;
    }

    &--hide,
    &--hidden {
      //opacity: 0;
      //pointer-events: none;

      span {
        opacity: 0;
        width: 0;
        overflow: hidden;
      }
    }

    &--inner {
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      color: $white;
      letter-spacing: 1px;
      vertical-align: middle;
      cursor: pointer;

      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);


      &:hover {
        letter-spacing: 5px;
        &:after {
          right: -40px;
          transform: rotate(180deg);
        }
      }

      &:after {
        content: "";
        display: none;
        position: absolute;
        right: -50px;
        top: 2px;
        transform: rotate(0);

        width: 20px;
        height: 18px;
        background-image: url('../img/ui/play.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin: 0 12px 3px;

        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }

  &__transition {
    width: 100%;
    min-height: 100%;
    background-color: $dark-blue;
    position: absolute;
    z-index: 2;
    opacity: 0;
    pointer-events: none;

    &--animate {
      animation: transitionOpacity 3s alternate;
    }
  }
}

@include keyframes(transitionOpacity)
{
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

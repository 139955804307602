.article-teaser {
  position: relative;
  padding: rem(40) 0;
  color: #06080B;

  &:after {
    @include titleDividerLine();
  }

  &__title {
    @include fnt($display-sans-font, 14, 400, 24, 2.16);
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }
}

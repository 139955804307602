.brand {
  @include fnt($base-sans-font, 12, 400, 16, 0.2);
  @include center();
  margin: 44px auto;
  transform: scale(.75);
  position: relative;



  @include above(850px) {
    transform: scale(.85);
  }

  @include above(959px) {
    transform: scale(.65);
  }

  @include above(1000px) {
    transform: scale(.7);
  }

  @include above(1050px) {
    transform: scale(.8);
  }

  @include above(1100px) {
    transform: scale(.9);
  }

  @include above(1280px) {
    transform: scale(1);
    margin: 70px auto;
  }

  &__inner {
    width: 900px;
    height: 651px;
    display: flex;
    position: relative;

    &:before {
      content:'';
      display: block;
      position: absolute;
      width: 100%;
      height: 148px;
      background-image: url('../img/ui/slider-shadow.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left bottom;
      left: 0;
      right: 0;
      bottom: -100px;
      z-index: -1;
    }
  }

  &__visual {
    position: relative;
    background: #1A2835;
    width: 546px;

    img {
      position: absolute;
      display: block;
      width: 646px;
      height: auto;
      top: -70px;
      left: -70px;
    }
  }

  &__cta {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  &__main {
    background: $white;
    width: 355px;
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  &__logo {
    margin: 0;
    img {
      max-width: 150px;
    }
  }

  &__intro {
    @include fnt-intro-center();
    position: relative;
    padding: 20px 0;

    p {
      margin: 0;
    }

    &:after {
      @include titleDividerLine() {
        left: 50%;
        transform: translateX(-50%);
      }
    }

  }

  &__content {
    padding: 20px 0;
    flex-grow: 1;

     p {
      margin: 0;
    }
  }
}


.tns-item {
  .table--specs,
  .table--specs caption,
  .brand__content,
  .brand__intro,
  .brand__logo {
    opacity: 0;
    margin-top: 20px;
    position: relative;
    transition-property: all, margin;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    transition-delay: 1s;
  }


}

.show-brands .tns-slide-active {

  .table--specs,
  .table--specs caption,
  .brand__content,
  .brand__intro,
  .brand__logo {
    opacity: 1;
    margin-top: 0;
    transition-duration: .6s;
  }

  .brand__logo {
    transition-delay: .5s;
  }

  .brand__intro {
    transition-delay: .7s;
  }

  .brand__content {
    transition-delay: .8s;
  }

  .table--specs caption {
    transition-delay: .9s;
  }

  .table--specs {
    transition-delay: 1s;
  }
}


.section--press-full .section__inner,
.section--press .section__inner {
  display: flex;
  flex-direction: column;

  @include above($tablet) {
    flex-direction: row;
    // min-height: 500px;
  }
}

.section--press-full .section__inner {
  @include above($tablet) {
    min-height: 100vh;
  }
}


.articles {
  position: relative;

  &__title {
    @include fnt($display-serif-font, 30, 400, 36, 1.2);
    color: #06080B;
    margin: 0;
  }


  &--latest {
    background-image: linear-gradient(137deg, #E8EDF1 0%, #B9BCC6 100%);
  }

  &--latest {
    padding: rem(40) rem(20);
    order: 1;

    @include above($tablet) {
      order: 2;
      width: 500px;
      max-width: 50%;
      padding: rem(40) rem(40) rem(40) rem(70);
    }
  }

  &--overview {
    padding: rem(40) rem(20);
    flex-grow: 1;

    @include above($tablet) {
      padding: rem(40) rem(70);
    }

    .article-teaser {
      max-width: rem(400);
    }
  }

  &--overview-highlighted {
    display: flex;
    flex-direction: column;

    @include above($tablet) {
      width: 540px;
      max-width: 50%;
    }

  }

  .btn--readmore {
    position: absolute;
    right: rem(20);
  }
}


.section--press-full  {
  background-image: linear-gradient(137deg, #E8EDF1 0%, #B9BCC6 100%);
}

// ==========================================================================
// Placeholders
// Placeholder styles to include as @extends
// ==========================================================================

%borderbox { box-sizing: border-box; }

%left { float: left; }
%right { float: right; }
%clear { @include clearfix(); }

%transition { transition: if(variable-exists(base-transition), $base-transition, 'all .3s ease');}

%basefont   { font-family: if(variable-exists(default-font-family), $default-font-family, sans-serif); }
%monofont   { font-family: if(variable-exists(monospace-font-family), $monospace-font-family, monospace); }
%titlefont  { font-family: if(variable-exists(title-font-family), $title-font-family, sans-serif); }

%textgradient-vertical--brown {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(161,79,0,1)), color-stop(100%, rgba(99,52,10,1)));
  background: -webkit-linear-gradient(90deg, rgba(99,52,10,1) 0%, rgba(161,79,0,1) 100%);

	color: $dark-brown;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}


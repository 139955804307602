@media screen and (max-width: 1100px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1099px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 768px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 767px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 480px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 479px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (min-width: 479px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 480px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 767px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 768px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1099px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1100px) {
  .foo {
    bar: 'above'; } }

html {
  box-sizing: border-box;
  line-height: 1.15; }

*,
*::before,
*::after {
  box-sizing: inherit; }

:root {
  -moz-tab-size: 4;
  tab-size: 4; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

hr {
  height: 0; }

abbr[title] {
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp,
pre {
  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  padding: 0; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

summary {
  display: list-item; }

.brewery__products .slider-controls:after {
  display: table;
  clear: both;
  content: ''; }

.brewery__kettle .brewery__number, .brewery__kettle .brewery__plus, .brewery__export h2 .brewery__number, .brewery__export h3 .brewery__number {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a14f00), color-stop(100%, #63340a));
  background: -webkit-linear-gradient(90deg, #63340a 0%, #a14f00 100%);
  color: #69370b;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text; }

body, .page {
  font-family: "freight-sans-pro", sans-serif;
  font-size: 1rem;
  line-height: 1.375;
  color: #7C838C; }

nav ol,
nav ul {
  list-style: none;
  padding: 0;
  margin: 0; }

nav a {
  text-decoration: none;
  color: inherit;
  transition: color .3s ease-in-out; }

h1 {
  font-family: "mostra-nuova", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0.15417em;
  color: #884A01; }

.l-header {
  position: sticky;
  top: 0;
  z-index: 999; }
  @media screen and (min-width: 990px) {
    .l-header {
      width: 240px;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh; } }
  @media screen and (min-width: 1440px) {
    .l-header {
      width: 315px; } }

@media screen and (min-width: 990px) {
  .l-main {
    width: calc(100% - 240px);
    margin-left: 240px;
    position: relative;
    min-height: 100vh; } }

@media screen and (min-width: 1440px) {
  .l-main {
    width: calc(100% - 315px);
    margin-left: 315px; } }

#scroll-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin-top: 87px; }
  @media screen and (min-width: 990px) {
    #scroll-box {
      margin-top: 0; } }

/* TODO: Optimize transition */
.background-enter {
  position: absolute !important;
  top: 0;
  opacity: 0;
  z-index: 1; }

.background-enter.background-enter-active {
  opacity: 1;
  transition: all 600ms linear; }

.background-exit {
  opacity: 1;
  position: absolute !important;
  top: 0;
  z-index: 0; }

.background-exit.background-exit-active {
  opacity: 0;
  transition: all 600ms linear; }

.age-check {
  max-width: 100%;
  min-height: 292px;
  background-color: #6E7F91;
  background-image: linear-gradient(145deg, #6E7F91 0%, #3F4D5C 100%);
  box-shadow: 0 4px 6px black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  padding: 30px;
  margin: 1.25rem;
  text-align: center; }
  @media screen and (min-width: 480px) {
    .age-check {
      padding: 40px;
      width: 550px;
      text-align: left; } }
  .age-check__inner {
    position: relative;
    padding-left: 0; }
    @media screen and (min-width: 480px) {
      .age-check__inner {
        padding-left: 100px; } }
    @media screen and (min-width: 600px) {
      .age-check__inner {
        padding-left: 160px; } }
    @media screen and (min-width: 480px) {
      .age-check__inner:after {
        content: '';
        width: 60px;
        height: 60px;
        display: block;
        background-image: url("../img/ui/bottlecap.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: -50px;
        margin-top: -5px;
        width: 120px;
        height: 110px;
        margin-top: -20px;
        left: 0; } }
    @media screen and (min-width: 600px) {
      .age-check__inner:after {
        width: 181px;
        height: 169px;
        margin-top: -40px; } }
  .age-check__title {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.25;
    letter-spacing: 0.05em;
    color: inherit;
    margin: 0; }
    @media screen and (min-width: 600px) {
      .age-check__title {
        font-size: 1.875rem;
        line-height: 1.2;
        letter-spacing: 0.04em; } }
  .age-check__content {
    font-family: "freight-display-pro", serif;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.18182;
    color: inherit;
    margin: 0; }
    @media screen and (min-width: 600px) {
      .age-check__content {
        font-size: 2.3125rem;
        line-height: 0.86486; } }
  .age-check__cta {
    margin-top: 30px;
    text-align: center;
    position: relative;
    z-index: 100; }
    @media screen and (min-width: 480px) {
      .age-check__cta {
        text-align: left; } }
    .age-check__cta .btn {
      min-width: 100%;
      cursor: pointer; }
      @media screen and (min-width: 480px) {
        .age-check__cta .btn {
          min-width: 100px; } }
      @media screen and (max-width: 600px) {
        .age-check__cta .btn:hover {
          background: transparent; } }
    .age-check__cta .btn + .btn {
      margin: 15px 0 0 0; }
      @media screen and (min-width: 480px) {
        .age-check__cta .btn + .btn {
          margin: 0 0 0 15px; } }
  .age-check--visible {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s ease-out; }
  .age-check--hide {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out; }
  .age-check__lang {
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 10px; }
    @media screen and (min-width: 480px) {
      .age-check__lang {
        margin-top: -35px;
        padding-bottom: 40px; } }
    .age-check__lang ul {
      margin: 0;
      padding: 0; }
    .age-check__lang li {
      display: inline-block;
      list-style: none;
      font-family: "freight-display-pro", serif;
      font-weight: bold;
      color: #06080B;
      cursor: pointer; }
      .age-check__lang li.active {
        color: #fff; }
      .age-check__lang li:last-child:after {
        content: none; }
      .age-check__lang li:after {
        content: "|";
        display: inline-block;
        padding: 0 12px;
        color: #06080B; }

.article {
  min-height: 100vh;
  background-image: linear-gradient(137deg, #E8EDF1 0%, #B9BCC6 100%);
  padding-bottom: 1.875rem; }
  .article__inner {
    max-width: 46.25rem;
    padding: 1.25rem;
    margin: auto;
    margin-bottom: 1.875rem; }
  .article__ui:after {
    display: table;
    clear: both;
    content: ''; }
  .article__ui .btn--back {
    float: right;
    padding-right: 0;
    min-width: auto; }
  .article__header {
    height: 17.5rem;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center; }
    .article__header::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      background: rgba(0, 0, 0, 0.5); }
  .article__title {
    font-family: "freight-display-pro", serif;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 1.1;
    letter-spacing: 0.04em;
    margin-bottom: 1.875rem;
    text-transform: none;
    color: #fff;
    z-index: 1;
    max-width: 330px;
    position: relative;
    text-align: center; }
  .article__intro {
    font-family: "freight-display-pro", serif;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.16667;
    color: #884A01;
    text-align: center;
    max-width: 450px;
    margin: 1.875rem auto; }
  .article__intro, .article__content {
    position: relative;
    padding-bottom: 1.875rem; }
    .article__intro:after, .article__content:after {
      content: '';
      width: 1.625rem;
      height: 0.4375rem;
      background-image: url("../img/ui/article_ornament_brown.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }

.article-nav {
  text-align: center; }
  .article-nav li {
    font-family: "freight-display-pro", serif;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.16667;
    color: #884A01;
    display: inline-block;
    cursor: pointer; }

.article-teaser {
  position: relative;
  padding: 2.5rem 0;
  color: #06080B; }
  .article-teaser:after {
    content: '';
    display: block;
    width: 100px;
    border-bottom: 1px solid rgba(151, 75, 2, 0.25);
    position: absolute;
    bottom: 0; }
  .article-teaser__title {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.71429;
    letter-spacing: 0.15429em;
    text-transform: uppercase; }
    .article-teaser__title a {
      text-decoration: none;
      color: inherit; }
  .article-teaser:last-of-type:after {
    display: none; }

.article-teaser--highlighted {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  min-height: 340px;
  order: 2; }
  .section--press-full .article-teaser--highlighted {
    flex-grow: 0; }
  @media screen and (min-width: 768px) {
    .article-teaser--highlighted {
      order: 1; } }
  .article-teaser--highlighted::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background: rgba(0, 0, 0, 0.5); }
  .article-teaser--highlighted .article-teaser__inner {
    position: relative;
    z-index: 1;
    display: inline-block;
    text-align: center;
    color: #fff; }
    @media screen and (min-width: 768px) {
      .article-teaser--highlighted .article-teaser__inner {
        max-width: 25rem; } }
  .article-teaser--highlighted .article-teaser__title {
    font-family: "freight-display-pro", serif;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.5;
    letter-spacing: 0.04em;
    text-transform: none;
    margin: 0;
    padding: 0 2em; }
    @media screen and (min-width: 768px) {
      .article-teaser--highlighted .article-teaser__title {
        font-size: 1.875rem;
        line-height: 1.1;
        letter-spacing: 0.04em;
        margin-bottom: 1.875rem; } }
    .article-teaser--highlighted .article-teaser__title a {
      text-decoration: none;
      color: inherit; }
  .article-teaser--highlighted .article-teaser__title,
  .article-teaser--highlighted .article-teaser__title a,
  .article-teaser--highlighted .btn {
    color: inherit; }

.article__content {
  font-size: 0.875rem;
  line-height: 1.57143;
  letter-spacing: 0.01643em;
  color: #06080B; }
  .article__content h2, .article__content h3, .article__content h4, .article__content h5, .article__content h6 {
    font-family: "mostra-nuova", sans-serif;
    color: #7C838C;
    font-weight: 400;
    margin: 1.25rem 0 0 0; }
  .article__content h2 {
    font-size: 1.375rem;
    line-height: 1.36364;
    letter-spacing: 0.09818em; }
  .article__content h3 {
    font-size: 1.125rem;
    line-height: 1.44444;
    letter-spacing: 0.12em; }
  .article__content h4, .article__content h5, .article__content h6 {
    font-size: 0.875rem;
    line-height: 1.71429;
    letter-spacing: 0.15429em; }
  .article__content ul, .article__content ol, .article__content p {
    margin: 1.875rem 0; }
  .article__content ol,
  .article__content ul {
    padding: 0 0 0 1.4em; }
  .article__content img {
    width: 100%;
    height: auto; }

.brand-tile {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-color: #fff;
  /*transition-property: background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;*/
  user-select: none; }
  @media screen and (min-width: 769px) {
    .brand-tile__box-shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 2px 2px 5px rgba(1, 1, 2, 0.5); } }
  .brand-tile__visual, .brand-tile__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .brand-tile__visual {
    z-index: 0;
    top: -9.0625%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity .3s ease-in-out; }
    .brand-tile__visual img {
      width: 100%;
      height: auto; }
  .brand-tile__content {
    z-index: 1;
    color: #fff;
    text-align: center;
    transition: transform .3s ease-in-out; }
  .brand-tile__logo {
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    margin: 0;
    padding: 0 0 5% 0;
    width: 70%; }
    @media screen and (min-width: 768px) {
      .brand-tile__logo {
        width: auto; } }
    .brand-tile__logo:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 150px;
      height: 2px;
      transform: translateX(-50%);
      background-color: #884A01; }
    .brand-tile__logo img {
      width: 100%;
      height: auto;
      max-width: 200px; }
      @media screen and (min-width: 768px) {
        .brand-tile__logo img {
          width: auto; } }
  .brand-tile__title, .brand-tile__tagline {
    text-shadow: 0 0 14px rgba(1, 1, 2, 0.7); }
  .brand-tile__title {
    font-family: "freight-display-pro", serif;
    font-weight: 300;
    font-size: 2rem;
    line-height: 1;
    margin: 0; }
  .brand-tile__tagline {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1;
    margin: .8em 0 0;
    text-transform: uppercase; }
  .brand-tile__header {
    position: absolute;
    padding-top: 18px;
    top: 178px;
    left: 0;
    right: 0; }
    .brand-tile__header:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -75px;
      width: 150px;
      height: 2px;
      background-color: #884A01; }

.brand-tile--1758 .brand-tile__logo img {
  width: 60%; }
  @media screen and (min-width: 769px) {
    .brand-tile--1758 .brand-tile__logo img {
      width: auto; } }

/*@include above($tablet + 1px) {
  .brand-tile:hover {
    transform: scale(.95);

    .brand-tile__content {
      transform: scale(1.05);
    }
  }
}*/
.brand {
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.33333;
  letter-spacing: 0.01667em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 44px auto;
  transform: scale(0.75);
  position: relative; }
  @media screen and (min-width: 850px) {
    .brand {
      transform: scale(0.85); } }
  @media screen and (min-width: 959px) {
    .brand {
      transform: scale(0.65); } }
  @media screen and (min-width: 1000px) {
    .brand {
      transform: scale(0.7); } }
  @media screen and (min-width: 1050px) {
    .brand {
      transform: scale(0.8); } }
  @media screen and (min-width: 1100px) {
    .brand {
      transform: scale(0.9); } }
  @media screen and (min-width: 1280px) {
    .brand {
      transform: scale(1);
      margin: 70px auto; } }
  .brand__inner {
    width: 900px;
    height: 651px;
    display: flex;
    position: relative; }
    .brand__inner:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 148px;
      background-image: url("../img/ui/slider-shadow.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left bottom;
      left: 0;
      right: 0;
      bottom: -100px;
      z-index: -1; }
  .brand__visual {
    position: relative;
    background: #1A2835;
    width: 546px; }
    .brand__visual img {
      position: absolute;
      display: block;
      width: 646px;
      height: auto;
      top: -70px;
      left: -70px; }
  .brand__cta {
    position: absolute;
    bottom: 10px;
    right: 10px; }
  .brand__main {
    background: #fff;
    width: 355px;
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column; }
  .brand__logo {
    margin: 0; }
    .brand__logo img {
      max-width: 150px; }
  .brand__intro {
    font-family: "freight-display-pro", serif;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.16667;
    color: #884A01;
    text-align: center;
    position: relative;
    padding: 20px 0; }
    .brand__intro p {
      margin: 0; }
    .brand__intro:after {
      content: '';
      display: block;
      width: 100px;
      border-bottom: 1px solid rgba(151, 75, 2, 0.25);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }
  .brand__content {
    padding: 20px 0;
    flex-grow: 1; }
    .brand__content p {
      margin: 0; }

.tns-item .table--specs,
.tns-item .table--specs caption,
.tns-item .brand__content,
.tns-item .brand__intro,
.tns-item .brand__logo {
  opacity: 0;
  margin-top: 20px;
  position: relative;
  transition-property: all, margin;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  transition-delay: 1s; }

.show-brands .tns-slide-active .table--specs,
.show-brands .tns-slide-active .table--specs caption,
.show-brands .tns-slide-active .brand__content,
.show-brands .tns-slide-active .brand__intro,
.show-brands .tns-slide-active .brand__logo {
  opacity: 1;
  margin-top: 0;
  transition-duration: .6s; }

.show-brands .tns-slide-active .brand__logo {
  transition-delay: .5s; }

.show-brands .tns-slide-active .brand__intro {
  transition-delay: .7s; }

.show-brands .tns-slide-active .brand__content {
  transition-delay: .8s; }

.show-brands .tns-slide-active .table--specs caption {
  transition-delay: .9s; }

.show-brands .tns-slide-active .table--specs {
  transition-delay: 1s; }

.brands-mobile-detail {
  padding: 1.25rem 1.25rem 0;
  max-width: 500px;
  margin: auto; }

.brand-mobile {
  max-width: 400px;
  margin: auto;
  padding: 0 1.25rem; }
  .brand-mobile p {
    margin: 0; }
  .brand-mobile__logo img {
    max-width: 150px; }
  .brand-mobile__inner {
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(1, 1, 2, 0.5);
    background-color: #fff; }
  .brand-mobile__header {
    font-family: "freight-display-pro", serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.14286;
    color: #884A01;
    padding: 1.25rem; }
  .brand-mobile__visual {
    position: relative;
    overflow: hidden; }
    .brand-mobile__visual img {
      display: block;
      width: 120%;
      height: auto;
      margin-left: -14%;
      margin-right: -6%;
      margin-bottom: -14%; }
  .brand-mobile__cta {
    font-size: 0.8125rem;
    line-height: 1.12308;
    letter-spacing: 0.01692em;
    width: 100%;
    padding: 1.25rem;
    text-decoration: none;
    color: #7C838C;
    display: flex;
    align-items: center; }
    .brand-mobile__cta span {
      padding: 0 1.25rem; }
    .brand-mobile__cta .icn {
      width: 0.4375rem;
      height: 0.75rem;
      background-size: contain;
      float: right;
      transform: scaleX(-1); }

.brand-mobile-btn {
  padding: 0 1.25rem;
  max-width: 400px;
  margin: auto; }

.brands-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  width: 100%; }
  @media screen and (min-width: 769px) {
    .brands-selector {
      max-width: 720px; } }

.brands-selector__item {
  position: relative;
  max-width: 320px;
  width: calc(50% - 20px);
  margin: 20px 10px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(1, 1, 2, 0.5); }
  @media screen and (min-width: 769px) {
    .brands-selector__item {
      box-shadow: none;
      margin: 20px;
      width: calc(50% - 40px); } }
  .brands-selector__item::before {
    content: '';
    display: block;
    padding-top: 100%;
    float: left; }
  .brands-selector__item::after {
    content: '';
    display: block;
    clear: both; }

.brands-selector__item__cta {
  background-color: #fff;
  display: none; }
  @media screen and (min-width: 360px) and (max-width: 768px) {
    .brands-selector__item__cta {
      display: block; } }

@media screen and (min-width: 769px) {
  .slider-container {
    height: 100%;
    position: relative; } }

.brands-wrapper--mobile {
  position: relative;
  width: 200%;
  padding: 0.625rem 0 2.5rem 0;
  transition: left .6s ease-in-out; }
  .brands-wrapper--mobile .brands-slider-overview,
  .brands-wrapper--mobile .brands-slider-wrapper {
    position: relative;
    left: 0;
    width: 50%;
    float: left; }
  .brands-wrapper--mobile .brands-slider-wrapper {
    height: auto;
    padding-top: 1.875rem; }

.brands-wrapper--desktop {
  min-height: 760px;
  height: 100vh; }
  .brands-wrapper--desktop .brands-slider-wrapper {
    height: 100%; }
  .brands-wrapper--desktop .slider {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%); }
  .brands-wrapper--desktop .brands-slider-overview {
    background-image: url("../img/ui/brands-chooser-bg.png");
    background-size: auto auto;
    background-position: center;
    background-repeat: no-repeat; }
  .brands-wrapper--desktop .brands-slider-overview,
  .brands-wrapper--desktop .brands-slider-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    transition-property: left, opacity;
    transition-duration: .6s;
    transition-timing-function: ease-in-out; }

.brands-slider-controls {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
  outline: 0; }
  .brands-slider-controls:after {
    display: table;
    clear: both;
    content: ''; }
  .brands-slider-controls .btn-ctrl--prev {
    float: left; }
  .brands-slider-controls .btn-ctrl--next {
    float: right; }
  .brands-slider-overview .brands-slider-controls {
    left: auto; }

.hide-brands {
  overflow: hidden; }
  .hide-brands .brands-wrapper--desktop .brands-slider-wrapper {
    left: 100%;
    opacity: 0;
    z-index: 0; }
  .hide-brands .brands-wrapper--desktop .brands-slider-overview {
    left: 0;
    opacity: 1;
    z-index: 1; }
  .hide-brands .brands-wrapper--mobile {
    left: 0; }

.show-brands {
  overflow: hidden; }
  .show-brands .brands-wrapper--desktop .brands-slider-wrapper {
    left: 0;
    opacity: 1;
    z-index: 1; }
  .show-brands .brands-wrapper--desktop .brands-slider-overview {
    left: -100%;
    opacity: 0;
    z-index: 0; }
  .show-brands .brands-wrapper--mobile {
    left: -100%; }

.brands-slider-selector {
  position: relative;
  z-index: 9; }

.btn-ctrl {
  font-family: "mostra-nuova", sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.15429em;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  background: #fff;
  padding: 0;
  border: 0;
  min-width: 55px;
  height: 55px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer; }
  .btn-ctrl__inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center; }
  .btn-ctrl__text {
    display: inline-block;
    overflow: hidden;
    position: relative;
    max-width: 0;
    opacity: 0;
    transition-property: padding, max-width, opacity;
    transition-duration: .3s;
    transition-delay: .3s, .3s, 0s;
    transition-timing-function: ease-in-out; }
  .btn-ctrl__icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    width: 45px;
    height: 55px; }
  .btn-ctrl:hover .btn-ctrl__text {
    max-width: 200px;
    opacity: 1;
    transition-delay: 0s, 0s, .3s; }
  button.btn-ctrl {
    appearance: none; }

.btn-ctrl--next,
.btn-ctrl--prev {
  color: #884A01; }
  .btn-ctrl--next .btn-ctrl__text,
  .btn-ctrl--prev .btn-ctrl__text {
    display: none; }
    @media screen and (min-width: 768px) {
      .btn-ctrl--next .btn-ctrl__text,
      .btn-ctrl--prev .btn-ctrl__text {
        display: block; } }

.btn-ctrl--prev .btn-ctrl__icon {
  background-image: url("../img/ui/arrow.svg"); }

.btn-ctrl--prev:hover .btn-ctrl__text {
  padding-right: 20px; }

.btn-ctrl--next .btn-ctrl__inner {
  justify-content: flex-end; }

.btn-ctrl--next .btn-ctrl__icon {
  background-image: url("../img/ui/arrow.svg");
  transform: scaleX(-1); }

.btn-ctrl--next:hover .btn-ctrl__text {
  padding-left: 20px; }

.btn-ctrl--view {
  color: #7C838C;
  opacity: .5;
  transition-property: opacity;
  transition-duration: .3s;
  transition-delay: .6s;
  transition-timing-function: ease-in-out;
  z-index: 99; }
  .btn-ctrl--view .btn-ctrl__icon {
    width: 55px;
    background-image: url("../img/ui/view.svg");
    background-position: 11px 11px; }
  .btn-ctrl--view:hover {
    transition-delay: .3s;
    opacity: 1; }
    .btn-ctrl--view:hover .btn-ctrl__text {
      padding-left: 10px;
      padding-right: 24px; }

.btn-ctrl--view-clear {
  color: #7C838C;
  z-index: 99;
  background: transparent;
  box-shadow: none; }
  .btn-ctrl--view-clear .btn-ctrl__icon {
    width: 18px;
    height: 18px;
    background-image: url("../img/ui/view-white.svg");
    background-size: contain;
    background-position: center center; }
  .btn-ctrl--view-clear .btn-ctrl__text {
    padding-left: 1em;
    padding-right: 24px;
    max-width: 100%;
    width: auto;
    opacity: 1;
    color: #fff;
    font-size: 0.8125rem;
    line-height: 1.125rem; }

button, button:focus, button:hover, button:active, a, a:focus, a:hover, a:active {
  outline: 0; }

.btn {
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.20833em;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  min-width: 10.625rem;
  padding: 1.16667em 1.5em;
  border: 0;
  border-radius: 2px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #884A01;
  background-color: transparent;
  transition: all .2s ease-in-out;
  cursor: pointer; }
  .btn:hover {
    backgbackground-colorround: #ECECED; }
  button.btn {
    appearance: none; }

.btn--show-brand {
  font-family: "mostra-nuova", sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  letter-spacing: 0.16615em;
  text-align: center;
  width: 100%; }
  .btn--show-brand .icn {
    width: 0.4375rem;
    height: 0.75rem;
    background-size: contain;
    float: right;
    transform: scaleX(-1); }

.btn--shadowed {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5); }
  .btn--shadowed:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4); }

.btn--outlined {
  border: 1px solid currentColor;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  transition-property: background, color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out; }
  .btn--outlined span {
    position: relative; }
  .btn--outlined:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .btn--outlined:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to right, #DDA168, #695441);
    opacity: 0;
    transition-property: opacity;
    transition-duration: .4s;
    transition-timing-function: ease-in-out; }
  .btn--outlined:hover {
    color: #fff;
    background-color: #695441; }
    .btn--outlined:hover:before {
      opacity: 1; }

.brand__cta .btn--outlined {
  background-color: rgba(255, 255, 255, 0.7); }

.btn--outlined-white {
  color: #fff;
  border: 1px solid currentColor;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  transition-property: background, color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out; }
  .btn--outlined-white span {
    position: relative; }
  .btn--outlined-white:hover {
    background-color: rgba(255, 255, 255, 0.3); }

.btn--transparent {
  font-family: "mostra-nuova", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.11438em;
  padding: 0.6875em 1.125em;
  background-color: transparent;
  color: #fff;
  border: 1px solid currentColor; }
  .btn--transparent:hover {
    background-color: rgba(255, 255, 255, 0.2); }

.btn--back-arrow {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  min-width: auto;
  padding: 0;
  overflow: hidden;
  background-image: url("../img/ui/arrow-back.svg");
  background-repeat: no-repeat;
  background-position: center; }
  .btn--back-arrow:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%; }

.section--contact .section__inner {
  position: relative;
  overflow: hidden; }

.contact__title {
  font-family: "freight-display-pro", serif;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 1.2;
  letter-spacing: 0.04em;
  color: #7C838C;
  margin: 0; }

.contact__intro {
  font-size: 0.875rem;
  line-height: 1.14286;
  letter-spacing: 0.01643em;
  position: relative; }

.contact__map {
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  display: none; }
  @media screen and (min-width: 768px) {
    .contact__map {
      display: block; } }
  .contact__map .map {
    width: 100%;
    height: 100%; }

.contact__info,
.contact__form {
  z-index: 9;
  height: 100%;
  background: rgba(255, 255, 255, 0.95); }

.contact__info {
  font-size: 0.75rem;
  line-height: 1.33333;
  letter-spacing: 0.01667em;
  position: relative;
  padding: 2.5rem 1.25rem;
  transition: transform .5s ease-in-out; }
  @media screen and (min-width: 768px) {
    .contact__info {
      max-width: calc(100% - 500px);
      min-width: 50%;
      padding: 2.5rem 4.375rem; } }
  .contact__info p {
    margin: 1.25rem 0; }
  @media screen and (min-width: 768px) {
    .contact__info__inner {
      max-width: 25rem; } }
  .contact__info .contact__intro {
    padding-bottom: 1.875rem;
    margin-bottom: 1.875rem; }
    .contact__info .contact__intro:after {
      content: '';
      display: block;
      width: 100px;
      border-bottom: 1px solid rgba(151, 75, 2, 0.25);
      position: absolute;
      bottom: 0; }

.contact__form {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100%;
  width: 100%;
  padding: 2.5rem 1.25rem;
  transition: right .5s ease-in-out; }
  @media screen and (min-width: 768px) {
    .contact__form {
      padding: 2.5rem 2.5rem 2.5rem 4.375rem;
      width: 500px; } }
  .contact__form .btn--back-arrow {
    position: absolute;
    top: 0;
    right: 0; }
  .contact__form__inner {
    position: relative; }
    @media screen and (min-width: 768px) {
      .contact__form__inner {
        max-width: 25rem; } }

.show-form .contact__info {
  transform: translateX(-100%); }

.show-form .contact__form {
  right: 0; }

.locations + .btn {
  margin-top: 1.25rem; }

.location {
  margin: 1.875rem 0; }
  .location__title {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.28571;
    letter-spacing: 0.15429em;
    text-transform: uppercase;
    margin: 0 0 1em; }
  .location [data-location]:hover {
    cursor: pointer;
    text-decoration: underline; }

.address-container {
  display: flex; }
  .address-container .address {
    width: 50%;
    padding-right: 20px; }

.address a {
  color: inherit;
  position: relative;
  display: inline-block;
  padding-bottom: 3px;
  text-decoration: none; }
  .address a, .address a:after {
    transition-property: opacity, left, right;
    transition-duration: .3s;
    transition-timing-function: ease-in-out; }
  .address a:after {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    background-color: currentColor;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0; }
  .address a:hover:after {
    left: 0;
    right: 0;
    opacity: 1; }

.address h5, .address p {
  margin: 0; }

.address h5 {
  font-weight: 400;
  color: #884A01; }

.maps__popup__title,
.maps__popup__address p {
  margin: 5px 0; }

.maps__popup__title {
  font-size: 1.625rem;
  font-weight: 400;
  letter-spacing: .07em; }

.maps__popup__address p {
  font-size: 0.875rem;
  line-height: 1.42857;
  letter-spacing: 0.01429em; }
  .maps__popup__address p a {
    color: inherit;
    position: relative;
    display: inline-block;
    padding-bottom: 3px;
    text-decoration: none; }
    .maps__popup__address p a, .maps__popup__address p a:after {
      transition-property: opacity, left, right;
      transition-duration: .3s;
      transition-timing-function: ease-in-out; }
    .maps__popup__address p a:after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      background-color: currentColor;
      bottom: 0;
      left: 50%;
      right: 50%;
      opacity: 0; }
    .maps__popup__address p a:hover:after {
      left: 0;
      right: 0;
      opacity: 1; }

.content__page .article__header {
  height: auto;
  min-height: 150px; }
  .content__page .article__header:before {
    content: none; }
  .content__page .article__header:after {
    content: "";
    background-image: url("../img/ui/title-divider.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 8px;
    position: absolute;
    left: 0;
    bottom: 10px;
    right: 0;
    margin: auto; }

.content__page a {
  color: #010102; }

.cookie-bar {
  font-size: 0.875rem;
  line-height: 1.28571;
  letter-spacing: 0.08714em;
  text-align: center;
  background: #7C838C;
  color: #fff;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999; }
  .cookie-bar p {
    margin: 0 1.25rem; }
  .cookie-bar .btn {
    font-size: 0.625rem;
    max-width: 100%;
    min-width: auto; }

.disclaimer p {
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66667;
  letter-spacing: 0.1em;
  color: #626F7B;
  margin: 0;
  padding: 0 20px;
  text-transform: uppercase; }

.site-footer {
  color: #fff; }
  .site-footer a {
    color: inherit;
    position: relative;
    display: inline-block;
    padding-bottom: 3px;
    text-decoration: none; }
    .site-footer a, .site-footer a:after {
      transition-property: opacity, left, right;
      transition-duration: .3s;
      transition-timing-function: ease-in-out; }
    .site-footer a:after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      background-color: currentColor;
      bottom: 0;
      left: 50%;
      right: 50%;
      opacity: 0; }
    .site-footer a:hover:after {
      left: 0;
      right: 0;
      opacity: 1; }
  .site-footer__legal {
    font-family: "freight-big-pro", serif;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: 0.1225em;
    text-align: center;
    background-color: #7C838C;
    padding: 25px 0; }
    .site-footer__legal .copyright,
    .site-footer__legal .nav-legal__item {
      display: block;
      padding: .5em 0; }
    .site-footer__legal .nav-legal {
      padding-right: 14px;
      margin-right: 13px;
      border-right: 1px solid #FFF;
      padding-bottom: 4px;
      display: inline-block;
      vertical-align: middle; }
    @media screen and (min-width: 480px) {
      .site-footer__legal .copyright,
      .site-footer__legal .nav-legal,
      .site-footer__legal .nav-legal__item {
        display: inline-block;
        padding: 0; }
      .site-footer__legal .copyright {
        padding-right: 14px;
        margin-right: 13px;
        border-right: 1px solid currentColor; }
      .site-footer__legal .nav-legal__item + .nav-legal__item {
        padding-left: 14px;
        margin-left: 13px;
        border-left: 1px solid currentColor; }
      .site-footer__legal .copyright {
        padding-bottom: 4px; }
      .site-footer__legal .nav-legal {
        padding-right: 14px;
        margin-right: 13px;
        border-right: 1px solid #FFF;
        padding-bottom: 4px;
        display: inline-block;
        vertical-align: middle; } }
  .site-footer span.radikal {
    display: inline-block;
    vertical-align: middle; }
    .site-footer span.radikal a {
      transition: opacity 0.3s; }
    .site-footer span.radikal a:hover {
      opacity: 0.7; }
      .site-footer span.radikal a:hover:after {
        display: none; }
    .site-footer span.radikal img {
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: middle; }

.site-footer__doormat {
  background-color: #131621; }

.nav-doormat {
  font-family: "mostra-nuova", sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1;
  letter-spacing: 0.1225em;
  color: #7C838C;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  max-width: 800px;
  margin: auto;
  padding: 2.5rem 0; }
  .nav-doormat--mobile {
    padding: 1.25rem; }
  .nav-doormat__column {
    width: 25%;
    flex-grow: 1; }
  .nav-doormat__title {
    font-family: "freight-display-pro", serif;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: 0.1225em;
    text-transform: none;
    margin: 1em 0 .9em 0;
    color: #fff; }

.nav-doormat__discover__item {
  margin: .5em auto;
  display: block; }

.nav-doormat__main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start; }

.nav-doormat__main__item {
  padding: 1em 0;
  width: 100%; }

.nav-doormat--mobile .nav-doormat__column {
  width: 33.333%; }
  .nav-doormat--mobile .nav-doormat__column:nth-child(1) {
    text-align: left;
    order: 1; }
  .nav-doormat--mobile .nav-doormat__column:nth-child(2) {
    text-align: right;
    order: 3; }
  .nav-doormat--mobile .nav-doormat__column:nth-child(3) {
    order: 2; }

.form .btn {
  margin-top: 1.875rem; }

.fieldset {
  border: 0;
  padding: 0;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.05);
  background-color: #fff; }

.field {
  position: relative;
  border: 3px solid #F0F0F0; }
  .field:before {
    content: '';
    display: block;
    position: absolute;
    left: -2px;
    top: 50%;
    bottom: 50%;
    width: 2px;
    transition-property: top, bottom;
    transition-duration: .2s;
    transition-timing-function: ease-in-out; }
  .field + .field {
    border-top: 0; }
  .field label {
    font-size: 0.75rem;
    line-height: 1.33333;
    letter-spacing: 0.01667em;
    padding: 0.625rem 0.625rem 0; }
  .field textarea {
    resize: none; }
  .field label,
  .field textarea,
  .field input[type="text"],
  .field input[type="email"] {
    display: block; }
  .field textarea,
  .field input[type="text"],
  .field input[type="email"] {
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.01438em;
    color: #010102;
    font-weight: 600;
    appearance: none;
    border: 0;
    background-color: transparent;
    width: 100%;
    padding: 0;
    padding: 0.3125rem 0.625rem; }
  .field--text {
    height: 3.75rem; }
    .field--text input {
      height: auto; }
  .field--textarea {
    height: 95px; }
    .field--textarea label {
      height: 1.5625rem; }
    .field--textarea input {
      height: 5.625rem; }

.field.focused:before, .field.error:before {
  top: -2px;
  bottom: -2px; }

.field.focused:before {
  background: linear-gradient(to bottom, #A14F00 0%, #63340A 89%); }

.field.error:before {
  background: red; }

.icn {
  display: inline-block;
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  background-position: center;
  background-repeat: no-repeat; }

.icn--arrow {
  background-image: url("../img/ui/arrow.svg"); }

.icn--subtitles {
  background-image: url("../img/ui/subtitles.svg"); }

.icn--volume {
  position: relative;
  background-image: url("../img/ui/volume_cut.svg"); }
  .icn--volume:before, .icn--volume:after {
    content: '';
    display: block;
    width: 1px;
    height: 9px;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    position: absolute;
    transition-property: height, transform;
    transition-duration: .2s;
    transition-timing-function: ease-in-out; }
  [data-state="off"] .icn--volume:before {
    height: 5px;
    transform: translateY(-50%) rotate(45deg); }
  [data-state="off"] .icn--volume:after {
    height: 5px;
    transform: translateY(-50%) rotate(-45deg); }

.icn--play {
  background-image: url("../img/ui/play.svg"); }

.icn--pause {
  position: relative; }
  .icn--pause:before, .icn--pause:after {
    content: '';
    display: block;
    background-image: url("../img/ui/pause.svg");
    width: 4px;
    height: 19px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .icn--pause:before {
    margin-left: -4px; }
  .icn--pause:after {
    margin-left: 4px; }

.section--intro {
  position: relative;
  overflow: hidden;
  padding: 0;
  min-height: auto; }
  @media screen and (min-width: 990px) {
    .section--intro {
      min-height: 100vh; } }
  .section--intro video {
    width: 100%;
    height: auto;
    border: none;
    display: block;
    margin-left: -1px; }
    @media screen and (min-width: 990px) {
      .section--intro video {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        min-width: 101%;
        min-height: 100%;
        width: auto;
        height: auto;
        overflow: hidden; } }

.intro__controllers {
  width: 100%;
  min-height: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0; }

.intro__replay {
  opacity: 1;
  transform: scale(1) rotate(0deg);
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 18%;
  background-image: url("../img/ui/replay.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1); }
  @media screen and (min-width: 600px) {
    .intro__replay {
      width: 60px;
      height: 60px;
      bottom: 14%; } }
  @media screen and (min-width: 768px) {
    .intro__replay:hover {
      opacity: .6;
      transform: scale(0.8) rotate(360deg); } }
  .intro__replay--hide {
    opacity: 0;
    transform: scale(0) rotate(360deg);
    pointer-events: none; }

.intro__discover {
  opacity: 1;
  color: #b05f1f;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 22px;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: .4s; }
  @media screen and (min-width: 600px) {
    .intro__discover {
      bottom: 35px; } }
  .intro__discover--hide {
    opacity: 0;
    bottom: -60px;
    pointer-events: none; }
  .intro__discover.arrow-down:after {
    content: "";
    width: 15px;
    height: 10px;
    margin: 5px auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -16px;
    background-image: url("../img/ui/arrow-down.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1); }
    @media screen and (min-width: 600px) {
      .intro__discover.arrow-down:after {
        width: 22px;
        height: 15px;
        bottom: -30px;
        margin: 10px auto; } }
  .intro__discover:hover {
    letter-spacing: 5px;
    transition-delay: 0s; }
    .intro__discover:hover.arrow-down:after {
      bottom: -35px; }

.intro__play-pause {
  display: inline-block; }

.intro__video-ended .intro__play-pause {
  display: none; }

.intro__play {
  opacity: 1;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 25px;
  margin: auto;
  text-align: center;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1); }
  .intro__play span {
    display: inline-block;
    vertical-align: middle;
    padding-top: 5px; }
  .intro__play--hide span, .intro__play--hidden span {
    opacity: 0;
    width: 0;
    overflow: hidden; }
  .intro__play--inner {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 1px;
    vertical-align: middle;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1); }
    .intro__play--inner:hover {
      letter-spacing: 5px; }
      .intro__play--inner:hover:after {
        right: -40px;
        transform: rotate(180deg); }
    .intro__play--inner:after {
      content: "";
      display: none;
      position: absolute;
      right: -50px;
      top: 2px;
      transform: rotate(0);
      width: 20px;
      height: 18px;
      background-image: url("../img/ui/play.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin: 0 12px 3px;
      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1); }

.intro__transition {
  width: 100%;
  min-height: 100%;
  background-color: #06080B;
  position: absolute;
  z-index: 2;
  opacity: 0;
  pointer-events: none; }
  .intro__transition--animate {
    animation: transitionOpacity 3s alternate; }

@-webkit-keyframes transitionOpacity {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes transitionOpacity {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-o-keyframes transitionOpacity {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes transitionOpacity {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.logo img {
  display: block; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999; }
  .modal__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #1A2835;
    opacity: .8;
    z-index: 0; }
  .modal__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2; }

.language-nav {
  font-family: "freight-display-pro", serif;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.05688em;
  margin-top: 2em; }
  .language-nav__list {
    display: flex;
    justify-content: center;
    align-items: center; }
  .language-nav__item {
    padding: 0 .4em;
    position: relative; }
    .language-nav__item + .language-nav__item {
      padding-left: calc(.4em + 1px); }
      .language-nav__item + .language-nav__item:before {
        content: '';
        width: 1px;
        height: 1em;
        position: absolute;
        background-color: currentColor;
        top: 50%;
        margin-top: -.5em;
        left: 0; }
    .language-nav__item [data-timeline-control="volume"] {
      width: auto; }
  .language-nav a:focus, .language-nav a:active, .language-nav a:hover, .language-nav a.active {
    color: #fff; }

.primary-nav {
  z-index: 1;
  position: relative; }
  .primary-nav__item {
    font-family: "freight-display-pro", serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.25;
    letter-spacing: 0.05em;
    color: #7C838C;
    margin: .5em 0;
    position: relative; }
    @media screen and (min-width: 1440px) {
      .primary-nav__item {
        font-size: 1.875rem;
        line-height: 1.2;
        margin: 2vh 0; } }
    .primary-nav__item:first-child {
      margin-top: 0; }
    .primary-nav__item > a {
      position: relative;
      display: block; }
    .primary-nav__item > a:before,
    .primary-nav__item > a:after {
      content: '';
      position: absolute;
      width: 4px;
      top: 50%;
      right: 0;
      bottom: 50%;
      transition-property: top, bottom;
      transition-duration: .3s;
      transition-timing-function: ease-in-out; }
    .primary-nav__item > a:before {
      background-color: #884A01;
      background-image: linear-gradient(to bottom, #A14F00 0%, #63340A 89%);
      z-index: 1; }
    .primary-nav__item > a:after {
      background-color: rgba(255, 255, 255, 0.2);
      z-index: 0; }
    .primary-nav__item:focus:not(.is-active) > a:after, .primary-nav__item:hover:not(.is-active) > a:after {
      background-color: rgba(255, 255, 255, 0.2);
      background-image: none; }
    .primary-nav__item:hover, .primary-nav__item:focus, .primary-nav__item:active, .primary-nav__item.is-active {
      color: #fff; }
    .primary-nav__item:hover > a:after, .primary-nav__item:focus > a:after, .primary-nav__item:active > a:after {
      top: 0;
      bottom: 0; }
    .primary-nav__item.is-active > a:before {
      top: 0;
      bottom: 0; }

.secondary-nav {
  text-align: center; }
  .secondary-nav__item {
    font-family: "freight-sans-pro", sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 3;
    letter-spacing: 0.20833em;
    color: #fff;
    text-transform: uppercase;
    position: relative; }
    .secondary-nav__item a {
      position: relative; }
      .secondary-nav__item a:before {
        content: '';
        position: absolute;
        display: inline-block;
        top: 50%;
        margin-top: -2px;
        left: 0;
        opacity: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #884A01;
        background-image: linear-gradient(to bottom, #A14F00 0%, #63340A 89%);
        transition: all .3s ease-in-out; }
    .secondary-nav__item.is-active a:before {
      left: -15px;
      opacity: 1; }

.sub-nav {
  display: none; }
  @media screen and (min-width: 990px) {
    .sub-nav {
      text-align: left;
      width: 50%;
      margin: 2vh 0 0 50%; } }
  .is-active .sub-nav {
    display: inline-block; }
  .sub-nav__item {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 2.57143;
    letter-spacing: 0.15429em;
    color: #7C838C;
    position: relative; }
    .sub-nav__item a {
      position: relative; }
      .sub-nav__item a:before {
        content: '';
        position: absolute;
        display: inline-block;
        top: 50%;
        margin-top: -2px;
        left: 0;
        opacity: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #884A01;
        background-image: linear-gradient(to bottom, #A14F00 0%, #63340A 89%);
        transition: all .3s ease-in-out; }
    .sub-nav__item.is-active a:before {
      left: -15px;
      opacity: 1; }
    .sub-nav__item:hover, .sub-nav__item:focus, .sub-nav__item:active, .sub-nav__item.is-active {
      color: #fff; }

.menu-toggle {
  width: 2.5rem;
  height: 2.5rem;
  top: 50%;
  right: 1.25rem;
  position: absolute;
  transform: translateY(-50%); }
  @media screen and (min-width: 990px) {
    .menu-toggle {
      display: none; } }
  .menu-toggle__inner {
    width: 1.8125rem;
    height: 0.1875rem;
    top: 50%;
    transform: translateY(-50%);
    right: 0; }
    .menu-toggle__inner, .menu-toggle__inner:before, .menu-toggle__inner:after {
      position: absolute;
      display: block;
      background-image: linear-gradient(to left, #A14F00 0%, #63340A 89%);
      transition-property: width, left;
      transition-duration: .3s;
      transition-timing-function: ease-in-out; }
    .menu-toggle__inner:before, .menu-toggle__inner:after {
      content: '';
      height: 100%;
      width: 2.1875rem;
      left: -0.375rem; }
    .menu-toggle__inner:before {
      top: -0.5625rem; }
    .menu-toggle__inner:after {
      top: 0.5625rem; }
  .menu-toggle.is-active .menu-toggle__inner {
    width: 2.1875rem; }
    .menu-toggle.is-active .menu-toggle__inner:before, .menu-toggle.is-active .menu-toggle__inner:after {
      width: 1.8125rem;
      left: 0; }

.pagination li {
  font-family: "freight-display-pro", serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.16667;
  color: #884A01;
  display: inline-block;
  cursor: pointer; }
  .pagination li.is-active {
    font-weight: 700; }
  .pagination li.pagination__item.prev {
    margin-right: 0.9375rem; }
  .pagination li.pagination__item.next {
    margin-left: 0.9375rem; }

.pagination--overview li.page:before {
  content: '-';
  display: inline-block;
  margin: 0 0.25rem; }

.pagination--overview li:hover span, .pagination--overview li.is-active span {
  border-bottom: 1px solid currentColor; }

.pagination--overview li.prev + li.page:before {
  display: none; }

.pagination--article {
  text-align: center; }
  .pagination--article li.pagination__item.prev {
    margin-right: 0.625rem; }
  .pagination--article li.pagination__item.next {
    margin-left: 0.625rem; }
  .pagination--article li.dots {
    width: 2.125rem;
    height: 0.4375rem;
    background-image: url("../img/ui/pages-dots.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
  .pagination--article li.prev:before {
    content: '<';
    margin-right: 0.4375rem; }
  .pagination--article li.next:after {
    content: '>';
    margin-left: 0.4375rem; }
  .pagination--article li.prev:hover a,
  .pagination--article li.next:hover a {
    border-bottom: 1px solid currentColor; }

@media screen and (min-width: 990px) {
  .scrollbar {
    width: 0.25rem;
    background: #010102;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0; } }

section, article {
  position: relative; }

.section--brands {
  background-color: #7E8D98;
  background-image: linear-gradient(144deg, #7E8D98 0%, #4A4D58 100%); }
  .section--brands .btn-ctrl--view {
    position: absolute;
    top: auto;
    bottom: 30px;
    left: 30px; }

.section--brewery {
  padding: 1.5625rem;
  background-color: #bbc0c6;
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .section--brewery {
      padding: 4.375rem; } }
  @media screen and (min-width: 990px) {
    .section--brewery {
      min-height: 100vh; } }
  .section--brewery:before {
    content: "";
    background: radial-gradient(circle closest-side, #e0e2e5, transparent);
    position: absolute;
    z-index: 0;
    left: -350px;
    top: -350px;
    width: 800px;
    height: 800px;
    pointer-events: none; }
  .section--brewery .section__inner {
    position: relative;
    z-index: 1; }

.brewery__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .brewery__row--first .brewery__col {
    margin-top: 0; }
    @media screen and (min-width: 600px) {
      .brewery__row--first .brewery__col {
        margin-top: 1.875rem; } }
    .brewery__row--first .brewery__col.brewery__intro {
      margin-top: 0; }
      @media screen and (max-width: 1260px) {
        .brewery__row--first .brewery__col.brewery__intro {
          width: 100%; } }
  .brewery__row--spacing {
    padding: 1.25rem 0;
    position: relative; }
    @media screen and (min-width: 768px) {
      .brewery__row--spacing {
        padding: 2.5rem 0; } }
  .brewery__row .btn {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.13071em; }

.brewery__col h2 {
  font-family: "mostra-nuova", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.08333em;
  text-transform: uppercase;
  color: #884A01;
  position: relative;
  margin-bottom: 1.875rem;
  z-index: 2; }
  .brewery__col h2:after {
    content: "";
    background-color: #fff;
    width: 8px;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -15px; }

.brewery__col p {
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #323538;
  text-align: justify; }

.brewery__col img {
  width: 100%;
  height: auto; }

.brewery__col-3 {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .brewery__col-3 {
      width: calc(50% - 40px); } }
  @media screen and (min-width: 1260px) {
    .brewery__col-3 {
      width: calc(33.33% - 50px); } }

.brewery__col-7 {
  width: 100%; }
  @media screen and (min-width: 1260px) {
    .brewery__col-7 {
      width: calc(68% - 50px); } }

.brewery__intro h1 {
  font-family: "freight-display-pro", serif;
  font-weight: 400;
  font-size: 2.5rem;
  letter-spacing: 0.025em;
  color: #323538;
  position: relative;
  margin-bottom: 3.125rem;
  margin-top: 0; }
  @media screen and (min-width: 600px) {
    .brewery__intro h1 {
      font-family: "freight-display-pro", serif;
      font-weight: 400;
      font-size: 3.125rem;
      letter-spacing: 0.02em; } }
  .brewery__intro h1:after {
    content: "";
    background-image: url("../img/ui/title-divider.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 8px;
    position: absolute;
    left: 0;
    bottom: -30px; }

.brewery__intro p {
  font-family: "freight-display-pro", serif;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  color: #323538;
  text-align: left; }

@media screen and (min-width: 768px) {
  .brewery__partnership h2,
  .brewery__brands-block h2 {
    display: flex;
    align-items: flex-end;
    min-height: 66px; } }

.brewery__animation h1,
.brewery__animation h2,
.brewery__animation p, .brewery__animation.brewery__worldwide,
.brewery__animation .btn__wrapper {
  transform: translateY(40px);
  opacity: 0;
  transition: all 1s cubic-bezier(0.28, 0.69, 0.23, 1);
  transition-delay: .6s; }

.brewery__animation p {
  transition-delay: .9s; }

.brewery__animation .btn__wrapper {
  transition-delay: 1.2s; }

.brewery__animation.brewery__products .slide.tns-slide-active h2,
.brewery__animation.brewery__products .slide.tns-slide-active .product__description,
.brewery__animation.brewery__products .slide.tns-slide-active .product__options li {
  transform: translateY(20px);
  opacity: 0;
  transition: all 1s cubic-bezier(0.28, 0.69, 0.23, 1);
  transition-delay: .6s; }

.brewery__animation.brewery__products .slide.tns-slide-active .product__description {
  transition-delay: .9s; }

.brewery__animation.brewery__products .slide.tns-slide-active .product__options li:nth-child(1) {
  transition-delay: .9s; }

.brewery__animation.brewery__products .slide.tns-slide-active .product__options li:nth-child(2) {
  transition-delay: 1s; }

.brewery__animation.brewery__products .slide.tns-slide-active .product__options li:nth-child(3) {
  transition-delay: 1.10s; }

.brewery__animation.brewery__products .slide.tns-slide-active .product__options li:nth-child(4) {
  transition-delay: 1.20s; }

.brewery__animation.brewery__products .slide.tns-slide-active .product__options li:nth-child(5) {
  transition-delay: 1.30s; }

.brewery__animation.brewery__products .slide.tns-slide-active .product__options li:nth-child(6) {
  transition-delay: 1.40s; }

.brewery__animation.brewery__partnership h2 {
  transition-delay: .9s; }

.brewery__animation.brewery__partnership p {
  transition-delay: 1.2s; }

.brewery__animation.brewery__brands-block h2 {
  transition-delay: 1.5s; }

.brewery__animation.brewery__brands-block p {
  transition-delay: 1.8s; }

.brewery__animation.brewery__worldwide h2 {
  transform: none;
  opacity: 1; }

.brewery__animation.brewery__kettle h3,
.brewery__animation.brewery__kettle img {
  transform: translateY(40px);
  opacity: 0;
  transition: all 1s cubic-bezier(0.28, 0.69, 0.23, 1);
  transition-delay: .9s; }

.brewery__animation.brewery__kettle img {
  transition-delay: 1.2s; }

.brewery__animation.has-entered h1,
.brewery__animation.has-entered h2,
.brewery__animation.has-entered p, .brewery__animation.has-entered.brewery__worldwide,
.brewery__animation.has-entered h3,
.brewery__animation.has-entered.brewery__products .slide.tns-slide-active h2,
.brewery__animation.has-entered.brewery__products .slide.tns-slide-active .product__description,
.brewery__animation.has-entered.brewery__products .slide.tns-slide-active .product__options li,
.brewery__animation.has-entered .btn__wrapper,
.brewery__animation.has-entered.brewery__kettle img {
  transform: translateY(0);
  opacity: 1; }

.brewery__row-worldwide {
  transform: scale(0.7); }
  @media screen and (min-width: 600px) {
    .brewery__row-worldwide {
      transform: scale(1); } }
  @media screen and (min-width: 1260px) {
    .brewery__row-worldwide {
      margin-top: -20%; } }
  @media screen and (min-width: 1650px) {
    .brewery__row-worldwide {
      margin-top: -10%; } }
  @media screen and (min-width: 2000px) {
    .brewery__row-worldwide {
      margin-top: 0; } }

.brewery__worldwide {
  margin-left: 20%;
  padding: 80px 0;
  position: relative;
  min-width: 252px; }
  @media screen and (min-width: 600px) {
    .brewery__worldwide {
      margin-left: 35%; } }
  .brewery__worldwide h2 {
    line-height: 2rem;
    position: relative;
    text-shadow: 38px 65px 10px rgba(150, 150, 150, 0.3); }
    .brewery__worldwide h2:after {
      content: none; }
  .brewery__worldwide .brewery__singleLine {
    background-color: #fff;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 4px;
    bottom: 90px;
    z-index: -2; }
  .brewery__worldwide .waypoint__animation {
    position: absolute;
    top: 0;
    height: 1px; }
  .brewery__worldwide .brewery__number {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 400;
    font-size: 7.5rem;
    letter-spacing: 0.00833em;
    color: #fff;
    position: absolute;
    left: -80px;
    top: 0; }
  .brewery__worldwide .brewery__title {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 600;
    font-size: 2.5rem;
    letter-spacing: 0.025em;
    color: #69370b; }
  .brewery__worldwide .brewery__subtitle {
    font-family: "freight-display-pro", serif;
    font-weight: 500;
    font-size: 1.875rem;
    letter-spacing: 0.03333em; }
  .brewery__worldwide .brewery__images > div {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain; }
  .brewery__worldwide .brewery__cap {
    background-image: url("../img/brewery/cap.png");
    width: 500px;
    height: 410px;
    z-index: -1;
    top: -8%;
    right: -125%; }
  .brewery__worldwide .brewery__map {
    background-image: url("../img/brewery/map.png");
    width: 500px;
    height: 500px;
    z-index: -5;
    top: -30%;
    right: -130%; }
  .brewery__worldwide .brewery__lines {
    background-image: url("../img/brewery/lines.png");
    width: 460px;
    height: 200px;
    z-index: 0;
    top: -6%;
    right: -118%; }
  .brewery__worldwide .brewery__splashes {
    background-image: url("../img/brewery/splashes.png");
    width: 390px;
    height: 420px;
    z-index: -1;
    top: -8%;
    right: -30%; }

.brewery__kettle {
  order: 1;
  width: 100%;
  margin-left: 0;
  margin-top: 3.75rem; }
  @media screen and (min-width: 768px) {
    .brewery__kettle {
      order: 0;
      width: calc(50% - 40px);
      margin-left: 0;
      margin-top: 0; } }
  .brewery__kettle h2 {
    margin-left: 12.5rem;
    line-height: 1.25rem; }
    .brewery__kettle h2:after {
      width: 100%;
      background-color: #884A01; }
  .brewery__kettle h3 {
    margin-top: 0;
    margin-left: 12.5rem;
    position: absolute; }
  .brewery__kettle .brewery__number {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 400;
    font-size: 13.75rem;
    letter-spacing: 0.00455em;
    text-shadow: 48px 35px 10px rgba(150, 150, 150, 0.3);
    position: absolute;
    left: -5.625rem;
    top: -2.5rem;
    line-height: 12.5rem; }
  .brewery__kettle .brewery__plus {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 400;
    font-size: 6.875rem;
    letter-spacing: 0.00909em;
    text-shadow: 48px 35px 10px rgba(150, 150, 150, 0.3);
    left: -9.375rem;
    top: -2.5rem;
    line-height: 12.5rem;
    position: absolute;
    display: inline-block; }
  .brewery__kettle .brewery__title {
    font-family: "freight-display-pro", serif;
    font-weight: 300;
    font-size: 4.375rem;
    letter-spacing: 0.01429em;
    color: #fff;
    text-transform: none; }
  .brewery__kettle .brewery__subtitle {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    letter-spacing: 0.05em;
    color: #fff;
    text-transform: uppercase;
    z-index: 1; }
  .brewery__kettle img {
    width: auto;
    height: auto;
    margin-top: -13.125rem;
    z-index: -4;
    position: relative; }

.brewery__brewing {
  margin-top: 2.5rem;
  order: 0; }
  @media screen and (min-width: 768px) {
    .brewery__brewing {
      order: 0; } }

.brewery__packaging {
  margin-top: 1.875rem; }
  @media screen and (max-width: 1260px) {
    .brewery__packaging {
      width: 100%; } }

.brewery__products {
  background-color: #fff;
  min-height: 420px;
  margin-top: 2.5rem; }
  @media screen and (min-width: 1260px) {
    .brewery__products {
      margin-top: 0; } }
  .brewery__products .only-slide .product__inner {
    padding: 0; }
  .brewery__products .only-slide .product__image {
    top: -3.75rem; }
  .brewery__products .products__slider-wrapper {
    position: relative; }
    @media screen and (min-width: 600px) {
      .brewery__products .products__slider-wrapper {
        position: static; } }
  .brewery__products .tns-inner {
    min-height: 480px; }
  .brewery__products .slider-controls {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    pointer-events: none; }
    @media screen and (min-width: 600px) {
      .brewery__products .slider-controls {
        position: static;
        transform: none;
        padding: 0 20px 20px; } }
    .brewery__products .slider-controls button {
      outline: none; }
    .brewery__products .slider-controls .btn-ctrl--next, .brewery__products .slider-controls .btn-ctrl--prev {
      pointer-events: auto; }
      @media screen and (min-width: 600px) {
        .brewery__products .slider-controls .btn-ctrl--next, .brewery__products .slider-controls .btn-ctrl--prev {
          float: left; } }
    .brewery__products .slider-controls .btn-ctrl--next {
      float: right; }
    .brewery__products .slider-controls .btn-ctrl__icon {
      background-size: 50%;
      width: 30px;
      height: 35px; }
    .brewery__products .slider-controls .btn-ctrl {
      min-width: 35px;
      height: 35px;
      background: transparent;
      box-shadow: none; }
  .brewery__products .slide h2,
  .brewery__products .slide .product__description,
  .brewery__products .slide .product__options li {
    transform: translateY(20px);
    opacity: 0;
    transition: all 1s cubic-bezier(0.28, 0.69, 0.23, 1);
    transition-delay: .3s; }
  .brewery__products .slide .product__description {
    transition-delay: .6s; }
  .brewery__products .slide .product__options li:nth-child(1) {
    transition-delay: .9s; }
  .brewery__products .slide .product__options li:nth-child(2) {
    transition-delay: 1s; }
  .brewery__products .slide .product__options li:nth-child(3) {
    transition-delay: 1.10s; }
  .brewery__products .slide .product__options li:nth-child(4) {
    transition-delay: 1.20s; }
  .brewery__products .slide .product__options li:nth-child(5) {
    transition-delay: 1.30s; }
  .brewery__products .slide .product__options li:nth-child(6) {
    transition-delay: 1.40s; }
  .brewery__products .slide.tns-slide-active h2,
  .brewery__products .slide.tns-slide-active .product__description,
  .brewery__products .slide.tns-slide-active .product__options li {
    transform: translateY(0);
    opacity: 1; }
  .brewery__products .product__inner {
    width: 100%;
    position: relative;
    padding-top: 0.9375rem;
    padding-right: 0.9375rem; }
    @media screen and (min-width: 600px) {
      .brewery__products .product__inner {
        width: 50%;
        padding-top: 0;
        padding-right: 0; } }
    .brewery__products .product__inner h2 {
      font-family: "freight-display-pro", serif;
      font-weight: 400;
      font-size: 2.25rem;
      line-height: 1;
      letter-spacing: 0.02778em;
      text-transform: none;
      margin-top: 0;
      margin-bottom: 0.625rem; }
      .brewery__products .product__inner h2.has-amount {
        padding-right: 6.5625rem; }
    .brewery__products .product__inner h3 {
      font-family: "freight-display-pro", serif;
      font-weight: 400;
      font-size: 1.5rem;
      letter-spacing: 0.04167em;
      color: #7C838C;
      margin-top: 0; }
  .brewery__products .product__options ul {
    margin: 0;
    padding: 0; }
    .brewery__products .product__options ul li {
      list-style: none;
      border-bottom: 1px solid rgba(124, 131, 140, 0.2);
      font-family: "freight-sans-pro", sans-serif;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.42857;
      letter-spacing: 0.07143em;
      padding: 0.625rem 0; }
      .brewery__products .product__options ul li:last-child {
        border-bottom: none; }
  .brewery__products .product__amount {
    font-family: "freight-display-pro", serif;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.04167em;
    color: #7C838C;
    position: absolute;
    right: 0;
    top: 8px; }
  .brewery__products .product__image {
    width: 90%;
    height: auto;
    margin: 0 auto;
    text-align: center; }
    .brewery__products .product__image img {
      max-width: 150px; }
    @media screen and (min-width: 600px) {
      .brewery__products .product__image {
        position: absolute;
        right: -100%;
        top: -2.5rem;
        width: 100%; }
        .brewery__products .product__image img {
          max-width: 260px; } }
  @media screen and (max-width: 600px) {
    .brewery__products .slide {
      padding: 1.875rem 1.875rem 1.875rem 2.5rem !important; } }
  @media screen and (min-width: 600px) {
    .brewery__products .slide {
      padding: 3.125rem 1.875rem 1.875rem; } }
  @media screen and (min-width: 600px) {
    .brewery__products .slide:nth-child(1) .product__inner .product__image img {
      max-width: 500px; } }
  @media screen and (min-width: 600px) {
    .brewery__products .slide:nth-child(2) .product__inner .product__image img {
      max-width: 300px; } }
  @media screen and (min-width: 600px) {
    .brewery__products .slide:nth-child(3) .product__inner .product__image {
      top: 3.125rem; }
      .brewery__products .slide:nth-child(3) .product__inner .product__image img {
        max-width: 300px; } }
  @media screen and (min-width: 600px) {
    .brewery__products .slide:nth-child(5) .product__inner .product__image {
      top: 0.625rem; }
      .brewery__products .slide:nth-child(5) .product__inner .product__image img {
        max-width: 280px; } }
  .brewery__products .tns-outer {
    margin-top: -20px; }
    @media screen and (min-width: 600px) {
      .brewery__products .tns-outer {
        margin-top: -30px; } }
  .brewery__products .slider-controls--hide {
    display: none; }

.brewery__export {
  width: 100%;
  padding: 5rem 0; }
  @media screen and (min-width: 768px) {
    .brewery__export {
      padding: 0; } }
  .brewery__export.has-entered .brewery__truck {
    left: 10px; }
  .brewery__export-inner {
    position: relative;
    margin-top: -5rem; }
    @media screen and (min-width: 768px) {
      .brewery__export-inner {
        margin-left: 35%;
        margin-top: 0; } }
  .brewery__export h2 {
    padding-left: 6.25rem;
    margin-bottom: 0; }
    @media screen and (min-width: 600px) {
      .brewery__export h2 {
        padding-left: 9.375rem; } }
    @media screen and (min-width: 768px) {
      .brewery__export h2 {
        margin-bottom: 1.875rem; } }
    .brewery__export h2:after {
      width: calc( 100% - 210px);
      left: 6.25rem;
      bottom: 2rem;
      background-color: #69370b; }
      @media screen and (min-width: 600px) {
        .brewery__export h2:after {
          width: calc( 100% - 306px);
          left: 9.5rem;
          bottom: 2.5rem; } }
    .brewery__export h2 .brewery__number {
      font-family: "mostra-nuova", sans-serif;
      font-weight: 400;
      font-size: 6.25rem;
      letter-spacing: 0.01em;
      position: absolute;
      left: 0;
      top: 0;
      line-height: 7.1875rem; }
      @media screen and (min-width: 600px) {
        .brewery__export h2 .brewery__number {
          font-family: "mostra-nuova", sans-serif;
          font-weight: 400;
          font-size: 9.375rem;
          letter-spacing: 0.00667em; } }
    .brewery__export h2 .brewery__title {
      font-family: "freight-display-pro", serif;
      font-weight: 300;
      font-size: 3.125rem;
      letter-spacing: 0.02em;
      color: #fff;
      line-height: 3.75rem;
      display: block; }
      @media screen and (min-width: 600px) {
        .brewery__export h2 .brewery__title {
          font-family: "freight-display-pro", serif;
          font-weight: 300;
          font-size: 4.375rem;
          letter-spacing: 0.01429em; } }
    .brewery__export h2 .brewery__subtitle {
      font-family: "mostra-nuova", sans-serif;
      font-weight: 400;
      font-size: 1rem;
      letter-spacing: 0.0625em;
      color: #fff;
      display: block;
      margin-top: 1rem; }
      @media screen and (min-width: 600px) {
        .brewery__export h2 .brewery__subtitle {
          font-family: "mostra-nuova", sans-serif;
          font-weight: 400;
          font-size: 1.25rem;
          letter-spacing: 0.05em;
          margin-top: 1.75rem; } }
  .brewery__export h3 {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0; }
    .brewery__export h3 .brewery__number {
      font-family: "mostra-nuova", sans-serif;
      font-weight: 600;
      font-size: 2.5rem;
      letter-spacing: 0.025em;
      display: block;
      margin-left: -1.875rem;
      line-height: 2.8125rem; }
      @media screen and (min-width: 600px) {
        .brewery__export h3 .brewery__number {
          font-family: "mostra-nuova", sans-serif;
          font-weight: 600;
          font-size: 3.125rem;
          letter-spacing: 0.02em;
          margin-left: -2.5rem;
          line-height: 3rem; } }
    .brewery__export h3 .brewery__title {
      font-family: "freight-display-pro", serif;
      font-weight: 300;
      font-size: 1.5rem;
      letter-spacing: 0.04167em;
      color: #fff; }
      @media screen and (min-width: 600px) {
        .brewery__export h3 .brewery__title {
          font-family: "freight-display-pro", serif;
          font-weight: 300;
          font-size: 2.125rem;
          letter-spacing: 0.02941em; } }
  .brewery__export .btn__wrapper {
    text-align: right; }

.brewery__ground {
  background-image: url("../img/brewery/ground.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 200%;
  height: 120px;
  position: absolute;
  z-index: -2;
  left: -60px;
  bottom: -100px; }
  @media screen and (min-width: 600px) {
    .brewery__ground {
      width: 100%;
      height: 120px;
      left: -10px;
      bottom: -30px; } }

.brewery__clouds {
  background-image: url("../img/brewery/clouds.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 850px;
  height: 170px;
  position: absolute;
  z-index: -2;
  left: -100px;
  top: 90px; }
  @media screen and (min-width: 600px) {
    .brewery__clouds {
      width: 1200px;
      height: 240px;
      left: -100px;
      top: -100px; } }

.brewery__truck {
  background-image: url("../img/brewery/truck.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 180px;
  height: 70px;
  position: absolute;
  left: -50%;
  bottom: 0;
  transition: left 1.2s cubic-bezier(0.28, 0.69, 0.23, 1);
  transition-delay: .4s; }
  @media screen and (min-width: 600px) {
    .brewery__truck {
      width: 380px;
      height: 140px;
      bottom: 40px; } }

.brewery__talk {
  text-align: center;
  margin: 0 auto; }
  .brewery__talk p {
    font-family: "freight-display-pro", serif;
    font-weight: 400;
    font-size: 1.25rem;
    letter-spacing: 0.05em;
    color: #323538;
    max-width: 720px;
    text-align: center; }
  .brewery__talk .btn {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.11438em; }

.history {
  position: relative;
  overflow: hidden;
  height: 100vh; }
  @media screen and (min-width: 768px) {
    .history {
      height: 100vh;
      min-height: 784px; } }
  .history__chapters {
    position: relative;
    z-index: 1;
    height: calc(100vh - 185px); }
    @media screen and (min-width: 768px) {
      .history__chapters {
        height: calc(100vh - 134px);
        min-height: 650px; } }
  .history__bottom {
    position: relative;
    z-index: 1; }
  .history__controls {
    height: 40px;
    background: #080B0E; }
  .history__timeline {
    height: 58px;
    background: #06080A;
    position: relative; }
    @media screen and (min-width: 768px) {
      .history__timeline {
        height: 94px; } }

.chapters {
  height: 100%;
  position: relative;
  z-index: 2; }
  .chapters__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    z-index: 0;
    transition: background 1s; }
    .chapters__bg img {
      position: relative;
      display: block;
      z-index: 0; }
    .chapters__bg:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(1, 1, 2, 0.59);
      z-index: 1; }

.history-item.chapter-content,
.history-item.chapter-intro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 40px);
  z-index: 1; }
  @media screen and (min-width: 768px) {
    .history-item.chapter-content,
    .history-item.chapter-intro {
      height: 100%; } }

.intro__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 1s ease-out; }
  .intro__bg.intro-id-1 {
    background-image: url("../img/history/bg-generation1.jpg"); }
  .intro__bg.intro-id-2 {
    background-image: url("../img/history/bg-generation2.jpg"); }
  .intro__bg.intro-id-3 {
    background-image: url("../img/history/bg-generation3.jpg"); }
  .intro__bg.intro-id-4 {
    background-image: url("../img/history/bg-generation4.jpg"); }
  .intro__bg.intro-id-5 {
    background-image: url("../img/history/bg-generation5.jpg"); }
  .intro__bg.intro-id-6 {
    background-image: url("../img/history/bg-generation6.jpg"); }
  .intro__bg.intro-id-7 {
    background-image: url("../img/history/bg-generation7.jpg"); }
  .intro__bg.intro-id-8 {
    background-image: url("../img/history/bg-generation8.jpg"); }
  .intro__bg.show {
    opacity: 1; }

.chapter-content {
  position: absolute;
  color: white;
  height: calc(100% - 40px);
  width: 100%;
  z-index: 1;
  padding: 0.625rem; }
  @media screen and (min-width: 768px) {
    .chapter-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.25rem;
      height: 100%; } }
  .chapter-content__inner {
    width: 100%;
    height: 100%; }

.chapter-template {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  margin: auto; }

.secondary-visual {
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(1, 1, 2, 0.2); }
  .secondary-visual__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

.history-visual {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(1, 1, 2, 0.2); }
  .history-visual__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat; }

.history-visual.full {
  height: 100%; }
  .history-visual.full .history-visual__inner {
    background-size: cover; }

.history-fact {
  font-family: "freight-display-pro", serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.28571; }
  @media screen and (min-width: 768px) {
    .history-fact {
      font-size: 1.125rem;
      line-height: 1.44444; } }
  .history-fact__title, .history-fact__content {
    margin: 0; }

.chapter-template--two-rows {
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap; }
  @media screen and (min-width: 840px) {
    .chapter-template--two-rows {
      max-width: 700px; } }
  @media screen and (min-width: 940px) {
    .chapter-template--two-rows {
      max-width: 800px; } }
  @media screen and (min-width: 1040px) {
    .chapter-template--two-rows {
      max-width: 900px; } }
  .chapter-template--two-rows .full-width-column {
    height: 50%;
    width: 100%;
    position: relative;
    padding: 0; }
    @media screen and (min-width: 768px) {
      .chapter-template--two-rows .full-width-column {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        flex-grow: 1;
        flex-direction: row;
        height: 46%;
        padding: 10px 0; } }
  @media screen and (max-width: 767px) {
    .chapter-template--two-rows .full-width-column.two-columns {
      display: flex;
      flex-direction: column;
      height: 66.666%; } }
  @media screen and (max-width: 767px) {
    .chapter-template--two-rows .full-width-column + .full-width-column {
      margin-top: 10px; } }
  @media screen and (max-width: 767px) {
    .chapter-template--two-rows .full-width-column.two-columns + .full-width-column {
      height: 33.333%; } }
  .chapter-template--two-rows .history-visual {
    flex-grow: 1; }
    @media screen and (max-width: 767px) {
      .chapter-template--two-rows .history-visual {
        height: 100%; } }
  .chapter-template--two-rows .history-fact {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(1, 1, 2, 0.58);
    text-align: center;
    overflow: hidden; }
    @media screen and (min-width: 768px) {
      .chapter-template--two-rows .history-fact {
        margin-top: 0;
        margin-right: 20px; } }
    .chapter-template--two-rows .history-fact__inner {
      padding: 0.625rem; }
      @media screen and (min-width: 768px) {
        .chapter-template--two-rows .history-fact__inner {
          width: 360px; } }
    .chapter-template--two-rows .history-fact__title {
      font-size: 1.5625rem;
      line-height: 1;
      position: relative;
      margin: 0 0 0.625rem;
      padding: 0 0 0.9375rem; }
      @media screen and (min-width: 768px) {
        .chapter-template--two-rows .history-fact__title {
          font-size: 4.0625rem;
          line-height: 1;
          margin: 0 0 1.25rem;
          padding: 0 0 1.5625rem; } }
      .chapter-template--two-rows .history-fact__title:after {
        content: '';
        width: 1.625rem;
        height: 0.4375rem;
        background-image: url("../img/ui/article_ornament_white.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%); }
    @media screen and (min-width: 768px) {
      .chapter-template--two-rows .history-fact__content {
        min-width: 200px;
        margin: auto; } }
  @media screen and (max-width: 767px) {
    .chapter-template--two-rows .full-width-column.two-columns .history-visual {
      height: 50%; }
    .chapter-template--two-rows .full-width-column.two-columns .secondary-visual,
    .chapter-template--two-rows .full-width-column.two-columns .history-fact {
      height: 50%; } }
  .chapter-template--two-rows .history-visual + .secondary-visual,
  .chapter-template--two-rows .history-visual + .history-fact {
    margin-top: 10px; }
    @media screen and (min-width: 768px) {
      .chapter-template--two-rows .history-visual + .secondary-visual,
      .chapter-template--two-rows .history-visual + .history-fact {
        margin-right: 0;
        margin-top: 0;
        margin-left: 20px; } }

.chapter-template--overlayed {
  height: 100%;
  position: relative; }
  @media screen and (min-width: 840px) {
    .chapter-template--overlayed {
      max-width: 700px; } }
  @media screen and (min-width: 940px) {
    .chapter-template--overlayed {
      max-width: 800px; } }
  @media screen and (min-width: 1040px) {
    .chapter-template--overlayed {
      max-width: 900px; } }
  @media screen and (min-width: 768px) {
    .chapter-template--overlayed {
      align-items: center; } }
  .chapter-template--overlayed .full-width-column {
    width: 100%;
    position: relative; }
    @media screen and (max-width: 767px) {
      .chapter-template--overlayed .full-width-column {
        height: 100%; } }
    @media screen and (min-width: 768px) {
      .chapter-template--overlayed .full-width-column {
        height: auto; } }
  .chapter-template--overlayed .history-visual {
    position: relative; }
    @media screen and (max-width: 767px) {
      .chapter-template--overlayed .history-visual {
        height: 33.333%; } }
    @media screen and (min-width: 768px) {
      .chapter-template--overlayed .history-visual {
        position: relative;
        height: auto;
        flex-grow: 1; }
        .chapter-template--overlayed .history-visual:before {
          display: block;
          padding-top: 66.7992%;
          width: 100%;
          content: ' '; } }
    @media screen and (max-width: 767px) {
      .chapter-template--overlayed .history-visual.full {
        height: 100%; } }
  .chapter-template--overlayed .history-fact-container {
    height: 66.666%;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 767px) {
      .chapter-template--overlayed .history-fact-container {
        margin: 0; } }
    @media screen and (min-width: 768px) {
      .chapter-template--overlayed .history-fact-container {
        flex-grow: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        bottom: -50%;
        width: 30%;
        max-width: 350px;
        z-index: 1;
        display: block;
        height: auto; } }
    @media screen and (max-width: 767px) {
      .chapter-template--overlayed .history-fact-container .history-fact {
        height: 50%; } }
  .chapter-template--overlayed .history-fact {
    padding: 0.625rem; }
    @media screen and (max-width: 767px) {
      .chapter-template--overlayed .history-fact {
        margin: 10px 0 0 0; } }
    @media screen and (min-width: 768px) {
      .chapter-template--overlayed .history-fact {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        padding: 3.75rem; } }
    .chapter-template--overlayed .history-fact, .chapter-template--overlayed .history-fact:before {
      background-color: rgba(1, 1, 2, 0.9); }
    @media screen and (min-width: 768px) {
      .chapter-template--overlayed .history-fact:before {
        content: '';
        display: block;
        left: 0;
        right: 0;
        bottom: 100%;
        top: -200%;
        position: absolute; } }
    .chapter-template--overlayed .history-fact__title {
      font-family: "freight-sans-pro", sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25; }
      @media screen and (min-width: 768px) {
        .chapter-template--overlayed .history-fact__title {
          font-size: 1.5rem;
          line-height: 1.25; } }
  .chapter-template--overlayed .history-fact-visual {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 20px 0;
    position: relative;
    overflow: hidden; }
    .chapter-template--overlayed .history-fact-visual:before {
      display: block;
      padding-top: 36.61202%;
      width: 100%;
      content: ' '; }
    @media screen and (max-width: 767px) {
      .chapter-template--overlayed .history-fact-visual {
        height: 50%;
        margin: 10px 0 0 0; } }
    @media screen and (min-width: 768px) {
      .chapter-template--overlayed .history-fact-visual {
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        padding: 0; } }
    .chapter-template--overlayed .history-fact-visual__inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
  .chapter-template--overlayed .history-visual + .history-fact-container {
    left: auto;
    right: 0; }

.chapter-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: white;
  z-index: 1;
  text-align: center;
  padding: 0.625rem;
  background: #000; }
  @media screen and (min-width: 768px) {
    .chapter-intro {
      padding: 2.5rem 0 7.5rem 0;
      height: 100%; } }
  .chapter-intro__title {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.1;
    letter-spacing: 0.229em;
    text-transform: uppercase;
    max-width: 700px; }
    @media screen and (min-width: 1100px) {
      .chapter-intro__title {
        font-size: 1.875rem;
        line-height: 1.13333;
        letter-spacing: 0.15267em; } }
    @media screen and (min-width: 1440px) {
      .chapter-intro__title {
        font-size: 2.25rem;
        line-height: 1.11111;
        letter-spacing: 0.12722em; } }
  .chapter-intro__meta__date {
    font-family: "freight-display-pro", serif;
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 1.25;
    letter-spacing: 0.27778em; }
    @media screen and (min-width: 1100px) {
      .chapter-intro__meta__date {
        font-size: 5rem;
        line-height: 1;
        letter-spacing: 0.125em; } }
    @media screen and (min-width: 1440px) {
      .chapter-intro__meta__date {
        font-size: 6.25rem;
        line-height: 1;
        letter-spacing: 0.1em; } }
  .chapter-intro__meta__title {
    font-family: "mostra-nuova", sans-serif;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 1.19444;
    letter-spacing: 0.27778em;
    text-transform: uppercase;
    margin: auto; }
    @media screen and (min-width: 1100px) {
      .chapter-intro__meta__title {
        font-size: 5rem;
        line-height: 1.1875;
        letter-spacing: 0.125em; } }
    @media screen and (min-width: 1440px) {
      .chapter-intro__meta__title {
        font-size: 6.25rem;
        line-height: 0.95;
        letter-spacing: 0.1em; } }
  .chapter-intro__meta + .chapter-intro__title {
    margin-top: 0.6em; }

.controls__inner {
  display: flex;
  margin: 0 0.3125rem;
  padding-top: 0.4375rem; }

.controls__spacer {
  flex-grow: 1; }

[data-timeline-control] {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 2.5rem;
  height: 2rem;
  background-color: transparent;
  cursor: pointer; }

[data-state="on"],
[data-state="off"] {
  transition: transform .3s ease-in-out; }

[data-state="on"] {
  opacity: 1; }

[data-state="off"] {
  opacity: .5; }

[data-timeline-control="playback"] {
  overflow: hidden; }
  [data-timeline-control="playback"] .icn, [data-timeline-control="playback"] .icn:before, [data-timeline-control="playback"] .icn:after {
    transition: all .3s ease-in-out;
    position: absolute; }
  [data-timeline-control="playback"][data-state="playing"] .icn--play {
    opacity: 0;
    transform: scale(0.2); }
  [data-timeline-control="playback"][data-state="playing"] .icn--pause {
    opacity: 1; }
    [data-timeline-control="playback"][data-state="playing"] .icn--pause:before {
      margin-left: -4px; }
    [data-timeline-control="playback"][data-state="playing"] .icn--pause:after {
      margin-left: 4px; }
  [data-timeline-control="playback"][data-state="playing"]:hover .icn--pause:before {
    margin-left: -5px; }
  [data-timeline-control="playback"][data-state="playing"]:hover .icn--pause:after {
    margin-left: 5px; }
  [data-timeline-control="playback"][data-state="paused"] .icn--pause {
    opacity: 0; }
    [data-timeline-control="playback"][data-state="paused"] .icn--pause:before {
      margin-left: -10px; }
    [data-timeline-control="playback"][data-state="paused"] .icn--pause:after {
      margin-left: 10px; }
  [data-timeline-control="playback"][data-state="paused"] .icn--play {
    opacity: 1;
    transform: scale(1); }

.controls__legend {
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  margin: 0;
  padding: 7px 0 0 5px;
  display: none; }
  @media screen and (min-width: 768px) {
    .controls__legend {
      display: block; } }

.history__overlay {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: calc( 100% - 142px);
  top: 0;
  left: 0;
  background-color: rgba(6, 8, 11, 0.9);
  cursor: pointer; }
  .history__overlay:hover span {
    letter-spacing: 0.3125rem; }
  .history__overlay:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 142px;
    bottom: -142px;
    left: 0; }
    @media screen and (min-width: 768px) {
      .history__overlay:after {
        height: 94px;
        bottom: -94px; } }
  @media screen and (min-width: 768px) {
    .history__overlay {
      height: calc( 100% - 94px); } }
  .history__overlay .icn--play {
    width: 1.125rem;
    height: 2.25rem;
    background-size: contain;
    vertical-align: middle;
    margin-right: 1.25rem; }
  .history__overlay span {
    font-family: "freight-sans-pro", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.075em;
    color: #fff;
    text-transform: uppercase;
    vertical-align: middle;
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1); }
    @media screen and (min-width: 768px) {
      .history__overlay span {
        font-size: 1.5rem;
        line-height: 1.25;
        letter-spacing: 0.05em; } }

.history__inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.subtitles {
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.14286;
  letter-spacing: 0.02857em;
  color: #fff;
  text-shadow: 1px 1px 0 #010102;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(1, 1, 2, 0.48);
  min-height: 40px;
  padding: 0.625rem;
  width: 100%;
  z-index: 2;
  text-align: center;
  bottom: 0;
  transform: translateY(100%);
  transition: transform .3s ease-in-out;
  transition-delay: .3s; }
  @media screen and (min-width: 768px) {
    .subtitles {
      font-size: 1.5rem;
      line-height: 1.16667;
      letter-spacing: 0.01667em; } }
  @media screen and (min-width: 768px) {
    .subtitles {
      min-height: 80px; } }
  .subtitles p {
    margin: 0;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    transition-delay: 0s; }
  .subtitles.is-shown {
    transform: translateY(0);
    transition-delay: 0s; }
    .subtitles.is-shown p {
      opacity: 1;
      transition-delay: .3s; }

.timeline__events {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none; }
  @media screen and (min-width: 768px) {
    .timeline__events {
      display: block; } }

.event-marker {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 1px solid #010102;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  margin-left: -5px; }
  .event-marker:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-image: radial-gradient(50% 119%, #D57F2C 32%, #63340A 100%);
    opacity: 1;
    transition: opacity .3s ease-in-out; }

.event-marker.in-visor {
  cursor: pointer;
  z-index: 9; }
  .event-marker.in-visor:before {
    opacity: 1; }
  .event-marker.in-visor:hover:before {
    opacity: 0; }

.event-bubble {
  position: absolute;
  padding: 0.625rem 1.25rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.95);
  transform: translate(-50%, -100%);
  box-shadow: 0 0 10px 0 rgba(1, 1, 2, 0.5);
  top: 20px;
  opacity: 0;
  z-index: -999;
  transition-property: top, opacity, z-index;
  transition-timing-function: ease-in-out;
  transition-duration: .3s, .3s, 0s;
  transition-delay: 0s, 0s, 1s;
  min-width: 10.375rem; }
  .event-bubble:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 8px 0 8px;
    border-color: rgba(255, 255, 255, 0.95) transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%);
    bottom: -14px; }
  .event-bubble.event-bubble-first {
    transform: translate(0, -100%);
    margin-left: -20px; }
    .event-bubble.event-bubble-first:after {
      transform: translateX(0);
      left: 12px; }
  .event-bubble.event-bubble-last {
    transform: translate(-100%, -100%);
    margin-left: 18px; }
    .event-bubble.event-bubble-last:after {
      transform: translateX(0);
      left: calc(100% - 24px); }
  .event-bubble.is-shown {
    transition-delay: .1s, .1s, 0s;
    top: 5px;
    opacity: 1;
    z-index: 9; }
  .event-bubble__date {
    font-size: 0.75rem;
    line-height: 1.33333;
    letter-spacing: 0.20833em; }
  .event-bubble__content {
    font-family: "freight-display-pro", serif;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.16667;
    color: #884A01;
    text-align: center;
    margin: 0 0 10px; }
  .event-bubble__date + .event-bubble__content {
    margin-top: 5px; }

.history__timeline {
  background-image: url("../img/ui/timeline-bg.png");
  background-repeat: repeat-x;
  background-position: center;
  background-size: auto 50px; }
  @media screen and (min-width: 768px) {
    .history__timeline {
      background-size: auto; } }
  .history__timeline:after, .history__timeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 47px;
    z-index: 9; }
  .history__timeline:before {
    left: 0;
    background-image: linear-gradient(to right, rgba(1, 1, 2, 0.5) 0%, rgba(1, 1, 2, 0) 100%); }
  .history__timeline:after {
    right: 0;
    background-image: linear-gradient(to left, rgba(1, 1, 2, 0.5) 0%, rgba(1, 1, 2, 0) 100%); }

.timeline {
  position: absolute;
  height: 50px;
  top: 4px;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .timeline {
      height: 80px;
      top: 7px; } }
  @media screen and (min-width: 1620px) {
    .timeline {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      right: auto !important; } }
  .timeline__inner {
    position: relative;
    height: 100%;
    background-image: url("../img/ui/timeline-bg.png");
    background-repeat: repeat-x;
    background-position: center; }
  .timeline__track {
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    left: -50%;
    background-image: url("../img/ui/timeline-track.svg");
    background-repeat: repeat-x;
    background-position: -4px center;
    opacity: .37;
    position: relative;
    z-index: 0; }
  .timeline__epochs {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    left: 16px; }

.epoch {
  font-size: 0.5625rem;
  line-height: 1.11111;
  letter-spacing: 0.16667em;
  color: #4A4D58;
  padding: 0;
  height: 100%;
  border-left: 1px dashed #4A4D58;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media screen and (min-width: 768px) {
    .epoch {
      font-size: 0.75rem;
      line-height: 1.08333;
      letter-spacing: 0.20833em; } }
  .epoch__date {
    display: inline-block;
    padding-top: 3px;
    position: absolute;
    white-space: nowrap;
    left: 10px; }
    @media screen and (min-width: 768px) {
      .epoch__date {
        padding-top: 5px; } }
  .epoch__event-name {
    color: #884A01;
    font-weight: 600;
    padding-bottom: 4px;
    position: absolute;
    bottom: 0;
    white-space: nowrap;
    left: 10px;
    opacity: 0;
    transition: opacity 1s; }
    @media screen and (min-width: 768px) {
      .epoch__event-name {
        padding-bottom: 8px; } }
  .epoch.show .epoch__event-name {
    opacity: 1; }
  .epoch:before, .epoch:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    left: -5px; }
  .epoch:before {
    border-width: 4px 4.5px 0 4.5px;
    border-color: #4A4D58 transparent transparent;
    top: 0; }
  .epoch:after {
    border-width: 0 4.5px 4px 4.5px;
    border-color: transparent transparent #4A4D58 transparent;
    bottom: 0; }

.history-item {
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: ease-in-out; }
  .history-item.show {
    transition-delay: 1s;
    opacity: 1; }
  .history-item.hide {
    transition-delay: 0s;
    opacity: 0; }

@media screen and (min-width: 768px) {
  .history-item .full-width-column.two-columns .secondary-visual,
  .history-item .full-width-column.two-columns .secondary-visual__inner,
  .history-item .full-width-column.two-columns .history-visual,
  .history-item .full-width-column.two-columns .history-visual__inner,
  .history-item .full-width-column.two-columns .history-fact,
  .history-item .chapter-template--two-rows .full-width-column,
  .history-item .chapter-template--overlayed .history-fact,
  .history-item .chapter-template--overlayed .history-fact-visual__inner,
  .history-item .chapter-template--overlayed .history-visual__inner,
  .history-item .chapter-intro__title,
  .history-item .chapter-intro__meta {
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; }
  .history-item.show .chapter-intro__meta {
    position: relative;
    animation-name: BOUNCE-FROM-TOP;
    animation-duration: 1.5s;
    animation-delay: 1.1s; }
  .history-item.show .chapter-intro__title {
    position: relative;
    animation-name: BOUNCE-FROM-TOP;
    animation-duration: 1.5s;
    animation-delay: 1s; }
  .history-item.show .secondary-visual__inner,
  .history-item.show .history-visual__inner {
    animation-duration: 15s;
    animation-delay: 1s; }
  .history-item.show .chapter-template--overlayed .history-fact-visual__inner {
    opacity: 0;
    animation-name: SLIDE-FROM-BOTTOM;
    animation-duration: 3s;
    animation-delay: 4s; }
  .history-item.show .chapter-template--overlayed .history-fact {
    opacity: 0;
    animation-name: SLIDE-FROM-TOP;
    animation-duration: 2s;
    animation-delay: 3s; }
  .history-item.show .chapter-template--two-rows .full-width-column:nth-child(1) {
    height: 100%;
    width: 100%;
    flex-grow: 0;
    animation-name: SHRINK;
    animation-duration: 5s;
    animation-delay: 1s; }
  .history-item.show .chapter-template--two-rows .full-width-column:nth-child(2) {
    height: 0;
    width: 100%;
    opacity: 0;
    animation-name: GROW;
    animation-duration: 10s;
    animation-delay: 1s; }
  .history-item.show .full-width-column.two-columns .history-visual {
    flex-grow: 1; }
  .history-item.show .full-width-column.two-columns .secondary-visual,
  .history-item.show .full-width-column.two-columns .history-fact {
    width: 0;
    margin: 0;
    animation-name: FACT;
    animation-duration: 2s;
    animation-delay: 11s; }
  .history-item.show .full-width-column.two-columns:nth-child(2) .secondary-visual {
    width: 0;
    margin: 0;
    animation-name: FACT-LEFT;
    animation-duration: 2s;
    animation-delay: 11s; }
  .history-item.show#chapter-id-1-1 .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT;
    animation-duration: 18s; }
  .history-item.show#chapter-id-1-1 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-RIGHT; }
  .history-item.show#chapter-id-1-2 .history-visual__inner {
    animation-name: KEN-FING-BURNS-RIGHT;
    animation-duration: 18s; }
  .history-item.show#chapter-id-1-2 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT; }
  .history-item.show#chapter-id-2-1 .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT;
    animation-duration: 15s;
    background-size: auto 100%; }
  .history-item.show#chapter-id-3-1 .history-visual__inner {
    animation-name: KEN-FING-BURNS-RIGHT;
    animation-duration: 29s; }
  .history-item.show#chapter-id-3-1 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT; }
  .history-item.show#chapter-id-4-1 .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT, PAN-IMAGE-TO-BOTTOM;
    animation-duration: 16s; }
  .history-item.show#chapter-id-4-1 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-RIGHT; }
  .history-item.show#chapter-id-4-2 .history-visual__inner {
    animation-name: KEN-FING-BURNS-RIGHT;
    animation-duration: 16s; }
  .history-item.show#chapter-id-4-2 .full-width-column:nth-child(1) .history-visual__inner {
    background-position: center 40%; }
  .history-item.show#chapter-id-4-2 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT;
    background-position: center 20%; }
  .history-item.show#chapter-id-5-1 .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT;
    animation-duration: 37s;
    background-position: center 10%; }
  .history-item.show#chapter-id-5-1 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-RIGHT;
    background-position: center 30%; }
  .history-item.show#chapter-id-6-1 .history-visual__inner {
    animation-name: KEN-FING-BURNS-RIGHT;
    animation-duration: 17s; }
  .history-item.show#chapter-id-6-1 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT;
    background-position: center 15%; }
  .history-item.show#chapter-id-6-2 .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT;
    animation-duration: 18s; }
  .history-item.show#chapter-id-6-2 .full-width-column:nth-child(1) .history-visual__inner {
    background-position: center top; }
  .history-item.show#chapter-id-6-2 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-RIGHT; }
  .history-item.show#chapter-id-7-1 .history-visual__inner {
    animation-name: KEN-FING-BURNS-RIGHT;
    animation-duration: 8s; }
  .history-item.show#chapter-id-7-1 .chapter-template--two-rows .full-width-column:nth-child(1) {
    animation-duration: 3s; }
  .history-item.show#chapter-id-7-1 .chapter-template--two-rows .full-width-column:nth-child(2) {
    animation-duration: 3s;
    animation-delay: 3s; }
  .history-item.show#chapter-id-7-1 .full-width-column.two-columns .history-fact {
    animation-delay: 3s; }
  .history-item.show#chapter-id-7-1 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT; }
  .history-item.show#chapter-id-7-2 .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT;
    animation-duration: 7s; }
  .history-item.show#chapter-id-7-2 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT; }
  .history-item.show#chapter-id-8-1 .history-visual__inner {
    animation-name: KEN-FING-BURNS-RIGHT;
    animation-duration: 21s; }
  .history-item.show#chapter-id-8-1 .full-width-column:nth-child(1) .history-visual__inner {
    background-position: center 10%; }
  .history-item.show#chapter-id-8-1 .full-width-column:nth-child(2) .history-visual__inner {
    animation-name: KEN-FING-BURNS-LEFT; }
  .history-item.show#chapter-id-8-1 .full-width-column:nth-child(2) .secondary-visual__inner {
    background-position: center top; }
  .history-item.show#chapter-id-8-1 .chapter-template--two-rows .history-fact__title {
    font-size: 3.125rem; }
  .history-item.hide .chapter-intro__meta {
    animation-name: BOUNCE-TO-TOP;
    animation-duration: 1.5s;
    animation-delay: 0s; }
  .history-item.hide .chapter-intro__title {
    animation-name: BOUNCE-TO-TOP;
    animation-duration: 1.5s;
    animation-delay: .1s; }
  .history-item.hide .history-visual__inner {
    transform: scale(1) translate(0, 0); }
  .history-item.hide .chapter-template--two-rows .full-width-column:nth-child(1) {
    height: 50%; }
  .history-item.hide .chapter-template--two-rows .full-width-column:nth-child(2) {
    height: 50%; }
  .history-item.hide .full-width-column.two-columns .secondary-visual,
  .history-item.hide .full-width-column.two-columns .history-fact {
    width: 360px;
    margin-left: 20px; }
  .history-item.hide#chapter-id-2-1 .history-visual__inner {
    background-size: auto 100%; }
  .history-item.hide#chapter-id-4-1 .history-visual__inner {
    background-position: 50% 40%; }
  .history-item.hide#chapter-id-4-1 .full-width-column:nth-child(2) .history-visual__inner {
    background-position: center center; }
  .history-item.hide#chapter-id-4-2 .full-width-column:nth-child(1) .history-visual__inner {
    background-position: center 40%; }
  .history-item.hide#chapter-id-4-2 .full-width-column:nth-child(2) .history-visual__inner {
    background-position: center 20%; }
  .history-item.hide#chapter-id-5-1 .history-visual__inner {
    background-position: center 10%; }
  .history-item.hide#chapter-id-5-1 .full-width-column:nth-child(2) .history-visual__inner {
    background-position: center 30%; }
  .history-item.hide#chapter-id-6-1 .full-width-column:nth-child(2) .history-visual__inner {
    background-position: center 15%; }
  .history-item.hide#chapter-id-6-2 .full-width-column:nth-child(1) .history-visual__inner {
    background-position: center top; }
  .history-item.hide#chapter-id-8-1 .full-width-column:nth-child(1) .history-visual__inner {
    background-position: center 10%; }
  .history-item.hide#chapter-id-8-1 .full-width-column:nth-child(2) .secondary-visual {
    margin-left: 0;
    margin-right: 20px; }
  .history-item.hide#chapter-id-8-1 .full-width-column:nth-child(2) .secondary-visual__inner {
    background-position: center top; }
  .history-item.hide#chapter-id-8-1 .chapter-template--two-rows .history-fact__title {
    font-size: 3.125rem; } }

@media screen and (max-width: 767px) {
  #chapter-id-2-1 .chapter-template--overlayed .history-visual.full .history-visual__inner {
    background-size: contain;
    background-position: center top; }
  #chapter-id-4-2 .chapter-template--two-rows .history-fact-container,
  #chapter-id-4-2 .chapter-template--two-rows .history-visual {
    height: 50%; }
  #chapter-id-4-2 .chapter-template--two-rows .full-width-column:nth-child(2) .history-visual,
  #chapter-id-4-2 .chapter-template--two-rows .history-fact-visual {
    height: 100%; }
  #chapter-id-4-2 .chapter-template--two-rows .history-visual__inner,
  #chapter-id-4-2 .chapter-template--two-rows .history-fact-visual__inner {
    background-position: center top; }
  #chapter-id-4-2 .chapter-template--two-rows .full-width-column:nth-child(2) .history-visual__inner {
    background-position: center 10%; }
  #chapter-id-5-1 .history-visual__inner {
    background-position: center 10%; }
  #chapter-id-5-1 .full-width-column:nth-child(2) .history-visual__inner {
    background-position: center 30%; }
  #chapter-id-6-1 .full-width-column:nth-child(2) .history-visual__inner {
    background-position: center 15%; }
  #chapter-id-6-2 .full-width-column:nth-child(1) .history-visual__inner {
    background-position: center top; }
  #chapter-id-8-1 .chapter-template--two-rows .full-width-column:nth-child(1),
  #chapter-id-8-1 .chapter-template--two-rows .full-width-column:nth-child(2) {
    height: 50%; }
  #chapter-id-8-1 .chapter-template--two-rows .full-width-column:nth-child(1) .history-visual__inner {
    background-position: center top; }
  #chapter-id-8-1 .chapter-template--two-rows .full-width-column:nth-child(2) .secondary-visual {
    margin-bottom: 10px; } }

@keyframes FADE-IN {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes FADE-OUT {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes PAN-IMAGE-TO-BOTTOM {
  0% {
    background-position: 50% 50%; }
  50% {
    background-position: 50% 40%; }
  100% {
    background-position: 50% 40%; } }

@keyframes KEN-FING-BURNS-LEFT {
  0% {
    transform: scale(1.2) translate(-5%, 2%); }
  100% {
    transform: scale(1) translate(0, 0); } }

@keyframes KEN-FING-BURNS-RIGHT {
  0% {
    transform: scale(1.2) translate(5%, -2%); }
  100% {
    transform: scale(1) translate(0, 0); } }

@keyframes FACT {
  0% {
    width: 0;
    margin-left: 0; }
  100% {
    width: 360px;
    margin-left: 20px; } }

@keyframes FACT-LEFT {
  0% {
    width: 0;
    margin-right: 0; }
  100% {
    width: 360px;
    margin-RIGHT: 20px; } }

@keyframes SHRINK {
  0% {
    height: 100%; }
  60% {
    height: 100%; }
  100% {
    height: 50%; } }

@keyframes GROW {
  0% {
    height: 0;
    opacity: 0; }
  30% {
    height: 0; }
  70% {
    height: 50%;
    opacity: 0; }
  100% {
    height: 50%;
    opacity: 1; } }

@keyframes BOUNCE-FROM-TOP {
  0% {
    opacity: 0;
    transform: translateY(-50px); }
  60% {
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes BOUNCE-TO-TOP {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  40% {
    transform: translateY(10px); }
  100% {
    opacity: 0;
    transform: translateY(-50px); } }

@keyframes SLIDE-FROM-TOP {
  0% {
    opacity: 0;
    transform: translateY(-50px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes SLIDE-TO-TOP {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-50px); } }

@keyframes SLIDE-FROM-BOTTOM {
  0% {
    opacity: 0;
    transform: translateY(50px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes SLIDE-TO-BOTTOM {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(50px); } }

.section--press-full .section__inner,
.section--press .section__inner {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 768px) {
    .section--press-full .section__inner,
    .section--press .section__inner {
      flex-direction: row; } }

@media screen and (min-width: 768px) {
  .section--press-full .section__inner {
    min-height: 100vh; } }

.articles {
  position: relative; }
  .articles__title {
    font-family: "freight-display-pro", serif;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 1.2;
    letter-spacing: 0.04em;
    color: #06080B;
    margin: 0; }
  .articles--latest {
    background-image: linear-gradient(137deg, #E8EDF1 0%, #B9BCC6 100%); }
  .articles--latest {
    padding: 2.5rem 1.25rem;
    order: 1; }
    @media screen and (min-width: 768px) {
      .articles--latest {
        order: 2;
        width: 500px;
        max-width: 50%;
        padding: 2.5rem 2.5rem 2.5rem 4.375rem; } }
  .articles--overview {
    padding: 2.5rem 1.25rem;
    flex-grow: 1; }
    @media screen and (min-width: 768px) {
      .articles--overview {
        padding: 2.5rem 4.375rem; } }
    .articles--overview .article-teaser {
      max-width: 25rem; }
  .articles--overview-highlighted {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      .articles--overview-highlighted {
        width: 540px;
        max-width: 50%; } }
  .articles .btn--readmore {
    position: absolute;
    right: 1.25rem; }

.section--press-full {
  background-image: linear-gradient(137deg, #E8EDF1 0%, #B9BCC6 100%); }

.site-header {
  background-color: #182129;
  height: 87px;
  text-align: center; }
  @media screen and (min-width: 990px) {
    .site-header {
      z-index: 1000; } }
  .site-header__inner {
    height: 100%; }
    @media screen and (min-width: 990px) {
      .site-header__inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        height: 100%;
        min-height: 700px; } }

.site-header__nav-wrapper,
.site-header__nav-wrapper__inner {
  background-color: #182129; }
  @media screen and (min-width: 990px) {
    .site-header__nav-wrapper,
    .site-header__nav-wrapper__inner {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      background-color: transparent;
      padding-bottom: 0; } }

.site-header__nav-wrapper {
  overflow: hidden;
  height: 0;
  transition: all 0.3s ease-in-out; }
  @media screen and (min-width: 990px) {
    .site-header__nav-wrapper, .site-header__nav-wrapper.is-shown {
      height: auto; } }

.site-header__nav-wrapper__inner {
  padding: 2.5rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15); }
  @media screen and (min-width: 990px) {
    .site-header__nav-wrapper__inner {
      padding: 0;
      border-top: none; } }

.site-header__branding {
  height: 100%; }
  @media screen and (min-width: 990px) {
    .site-header__branding {
      height: auto; } }
  .site-header__branding .logo {
    display: block;
    width: 60px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .site-header__branding .logo img {
      display: block;
      width: 100%;
      height: auto; }
    @media screen and (min-width: 990px) {
      .site-header__branding .logo {
        width: 114px;
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0, 0); } }
    @media screen and (min-width: 990px) {
      .site-header__branding .logo {
        margin-top: 6vh; } }

@media screen and (min-width: 990px) {
  .site-header__primary-nav {
    padding-top: 6vh;
    flex-grow: 1; } }

@media screen and (min-width: 990px) {
  .site-header__secondary-nav {
    padding-bottom: 4vh; } }

.site-header__disclaimer {
  display: none; }
  @media screen and (min-width: 990px) {
    .site-header__disclaimer {
      padding-bottom: 3vh;
      display: block; } }

.table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0; }
  .table th, .table td {
    padding: 0; }

.table--specs th, .table--specs td {
  border-bottom: 1px solid rgba(151, 75, 2, 0.25); }

.table--specs th {
  font-family: "mostra-nuova", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.71429;
  letter-spacing: 0.08571em;
  color: #010102;
  text-align: left; }

.table--specs td {
  font-family: "freight-display-pro", serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.16667;
  color: #884A01;
  text-align: center;
  text-align: right; }

.table--specs caption {
  font-family: "freight-display-pro", serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.16667;
  color: #884A01;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase; }

.visor {
  width: 39px;
  height: 52px;
  position: absolute;
  top: -1px;
  bottom: -1px;
  z-index: 2;
  background-image: url("../img/ui/timeline-visor.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-50%); }
  @media screen and (min-width: 768px) {
    .visor {
      width: 63px;
      top: -2px;
      bottom: -2px;
      height: 84px; } }
  .visor:after, .visor:before {
    content: '';
    display: block;
    width: 100vw;
    height: 50px;
    position: absolute;
    background-color: rgba(1, 1, 2, 0.35);
    top: 1px; }
    @media screen and (min-width: 768px) {
      .visor:after, .visor:before {
        height: 80px;
        top: 2px; } }
  .visor:before {
    right: 0;
    margin-right: 39px; }
    @media screen and (min-width: 768px) {
      .visor:before {
        margin-right: 63px; } }
  .visor:after {
    left: 100%; }

.waypoint {
  display: block;
  height: 1px;
  position: absolute;
  top: 50vh;
  width: 100%;
  z-index: 6000; }

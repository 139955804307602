.section--contact .section__inner {
  position: relative;
  overflow: hidden;
}

.contact__title {
  @include fnt($display-serif-font, 30, 400, 36, 1.2);
  color: $grey;
  margin: 0;
}

.contact__intro {
  @include fntSize(14, 16, 0.23);

  position: relative;
}

.contact__map {
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  display: none;

  @include above($tablet) {
    display: block;
  }

  .map {
    width: 100%;
    height: 100%;
  }

}

.contact__info,
.contact__form {
  z-index: 9;
  height: 100%;
  background: rgba($white, .95);

}

.contact__info {
  @include fntSize(12, 16, 0.2);
  position: relative;
  padding: rem(40) rem(20);
  transition: transform .5s ease-in-out;

  @include above($tablet) {
    max-width: calc(100% - 500px);
    min-width: 50%;
    padding: rem(40) rem(70);
  }

  p {
    margin: rem(20) 0;
  }

  &__inner {
    @include above($tablet) {
      max-width: rem(400);
    }
  }

  .contact__intro {
    padding-bottom: rem(30);
    margin-bottom: rem(30);

    &:after {
      @include titleDividerLine();
    }
  }
}

.contact__form {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100%;
  width: 100%;
  padding: rem(40) rem(20);
  transition: right .5s ease-in-out;

  @include above($tablet) {
    padding: rem(40) rem(40) rem(40) rem(70);
    width: 500px;
  }


  .btn--back-arrow {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__inner {
    position: relative;

    @include above($tablet) {
      max-width: rem(400);
    }
  }

}


.show-form {
  .contact__info {
    transform: translateX(-100%);
  }
  .contact__form {
    right: 0;
  }
}

.pagination {

  li {
    @include fnt($display-serif-font, 18, 400, 21);
    color: $brown;
    display: inline-block;
    cursor: pointer;

    &.is-active {
      font-weight: 700;
    }

    &.pagination__item.prev {
      margin-right: rem(15);
    }

    &.pagination__item.next {
      margin-left: rem(15);
    }

  }

}


.pagination--overview {
  li {

    &.page{
      &:before {
        content: '-';
        display: inline-block;
        margin: 0 rem(4);
      }
    }

    &:hover,
    &.is-active {
      span {
        border-bottom: 1px solid currentColor;
      }
    }
  }

  li.prev + li.page {
    &:before {
      display: none;
    }
  }
}


.pagination--article {
  text-align: center;

  // li.page {
  //   margin-left: 6px;

  //   span {
  //     @include circle(5px);
  //     margin-top: -7px;
  //     position: relative;
  //     display: block;
  //     border: 1px solid $brown;
  //   }
  // }

  // li.prev + li.page {
  //   margin-left: 0;
  // }

  li.pagination__item.prev {
    margin-right: rem(10);
  }

  li.pagination__item.next {
    margin-left: rem(10);
  }

  li.dots {
    width: rem(34);
    height: rem(7);
    background-image: url('../img/ui/pages-dots.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  li.prev:before {
    content: '<';
    margin-right: rem(7);
  }

  li.next:after {
    content: '>';
    margin-left: rem(7);
  }

  li.prev,
  li.next {
    &:hover {
      a {
        border-bottom: 1px solid currentColor;
      }
    }
  }
}

// ==========================================================================
// Variables
// ==========================================================================

$root-box-sizing: border-box;

$mobile: 480px;
$tablet-xs: 600px;
$tablet: 768px;
$desktop-sm: 990px;
$desktop: 1100px;
$desktop-md: 1260px;
$desktop-xl: 1440px;
$hd: 1650px;
$desktop-xxl: 2000px;

$base-margin: 20px;

// Colors
$black: #010102;
$dark-blue: #06080B;

$white: #fff;

$grey: #7C838C;
$bluegrey: #818992;
$dark-gray: #323538;

$light-brown: #b05f1f;
$brown: #884A01;
$dark-brown: #69370b;

$gradient-brown: linear-gradient(to bottom, #A14F00 0%, #63340A 89%);

$error-color: red;


// Paths
$base-path: '../';
$base-font-path: $base-path + 'fonts/';
$base-img-path:  $base-path + 'img/';




// Fonts

$base-sans-font: 'freight-sans-pro', sans-serif; // Roboto
$base-serif-font:  'freight-big-pro', serif; // Taviraj
$display-sans-font:  'mostra-nuova', sans-serif;
$display-serif-font:  'freight-display-pro', serif; // Spectral

$default-font-family: $base-sans-font;



// Transitions
$base-transition: 'all .3s ease';

.language-nav {
  @include fnt($display-serif-font, 16, 700, null, 0.91);
  margin-top: 2em;

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    padding: 0 .4em;
    position: relative;

    & + & {
      padding-left: calc(.4em + 1px);

      &:before {
        content: '';
        width: 1px;
        height: 1em;
        position: absolute;
        background-color: currentColor;
        top: 50%;
        margin-top: -.5em;
        left: 0;
      }
    }

    [data-timeline-control="volume"] {
      width: auto;
    }
  }

  a {
    &:focus,
    &:active,
    &:hover,
    &.active {
      color: $white;
    }
  }


}

.timeline__events {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;

  @include above($tablet) {
    display: block;
  }
}

.event-marker {
  width: 10px;
  height: 10px;
  background-color: $white;
  border: 1px solid $black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  margin-left: -5px;


  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-image: radial-gradient(50% 119%, #D57F2C 32%, #63340A 100%);
    opacity: 1;
    transition: opacity .3s ease-in-out;
  }

}


.event-marker.in-visor {
  cursor: pointer;

  z-index: 9;

  &:before {
    opacity: 1;
  }

  &:hover {
    &:before {
      opacity: 0;
    }
  }
}


.event-bubble {
  $bg-color: rgba($white, .95);
  position: absolute;
  padding: rem(10) rem(20);
  text-align: center;
  background-color: $bg-color;
  transform: translate(-50%, -100%);
  box-shadow: 0 0 10px 0 rgba($black, .50);
  top: 20px;
  opacity: 0;
  z-index: -999;
  transition-property: top, opacity, z-index;
  transition-timing-function: ease-in-out;
  transition-duration: .3s, .3s, 0s;
  transition-delay: 0s, 0s, 1s;
  min-width: rem(166);

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 8px 0 8px;
    border-color: $bg-color transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%);
    bottom: -14px;
  }

  &.event-bubble-first {
    transform: translate(0, -100%);
    margin-left: -20px;

    &:after {
      transform: translateX(0);
      left: 12px;
    }
  }

  &.event-bubble-last {
    transform: translate(-100%, -100%);
    margin-left: 18px;

    &:after {
      transform: translateX(0);
      left: calc(100% - 24px);
    }
  }

  &.is-shown {
    transition-delay: .1s, .1s, 0s;

    top: 5px;
    opacity: 1;
    z-index: 9;
  }

  &__date {
    @include fntSize(12, 16, 2.5);
  }

  &__content {
    @include fnt-intro-center();
    margin: 0 0 10px;
  }

  &__date + &__content {
    margin-top: 5px;
  }
}

.menu-toggle {
  width: rem(40);
  height: rem(40);
  top: 50%;
  right: rem(20);
  position: absolute;
  transform: translateY(-50%);
  // background-image: url('../img/ui/hamburger.svg');
  // background-position: center;
  // background-repeat: no-repeat;

  @include above($desktop-sm) {
    display: none;
  }

  &__inner {
    width: rem(29);
    height: rem(3);
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    &,&:before,&:after {
      position: absolute;
      display: block;
      background-image: linear-gradient(to left, #A14F00 0%, #63340A 89%);
      transition-property: width, left;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
    }

    &:before,&:after {
      content: '';
      height: 100%;
      width: rem(35);
      left: rem(-6);
    }

    &:before {
      top: rem(-9);
    }

    &:after {
      top: rem(9);
    }

  }

  &.is-active {
    .menu-toggle__inner {
      width: rem(35);

      &:before, &:after {
        width: rem(29);
        left: 0;
      }
    }
  }
}

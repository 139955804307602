.content {
  &__page {
    .article__header {
      height: auto;
      min-height: 150px;


      &:before {
        content: none;
      }

      &:after {
        content: "";
        background-image: url('../img/ui/title-divider.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 22px;
        height: 8px;
        position: absolute;
        left: 0;
        bottom: 10px;
        right: 0;
        margin: auto;
      }
    }

    a {
      color: $black;
    }
  }
}

.icn {
  display: inline-block;
  position: relative;
  width: rem(20);
  height: rem(20);
  background-position: center;
  background-repeat: no-repeat;
}

.icn--arrow {
  background-image: url('../img/ui/arrow.svg');
}


.icn--subtitles {
  background-image: url('../img/ui/subtitles.svg');
}

// .icn--volume {
//   background-image: url('../img/ui/volume.svg');
// }

.icn--volume {
  position: relative;
  background-image: url('../img/ui/volume_cut.svg');

  &:before,
  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 9px;
    background: $white;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    position: absolute;
    transition-property: height, transform;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
  }


  [data-state="off"] & {

    &:before {
      height: 5px;
      transform: translateY(-50%) rotate(45deg);
    }
    &:after {
      height: 5px;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

.icn--play {
 background-image: url('../img/ui/play.svg');
}


.icn--pause {
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    background-image: url('../img/ui/pause.svg');
    width: 4px;
    height: 19px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &:before { margin-left: -4px; }
  &:after { margin-left: 4px; }

}


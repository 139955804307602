.history__timeline {
  background-image: url('../img/ui/timeline-bg.png');
  background-repeat: repeat-x;
  background-position: center;
  background-size: auto $timeline-height-mobile;

  @include above($tablet) {
    background-size: auto;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 47px;
    z-index: 9;
  }

  &:before {
    left: 0;
    background-image: linear-gradient(to right, rgba($black,.5) 0%, rgba($black,0) 100%);
  }

  &:after {
    right: 0;
    background-image: linear-gradient(to left, rgba($black,.5) 0%, rgba($black,0) 100%);
  }
  // &:after {
  //   content: '';
  //   position: absolute;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   height: 16px;
  //   background-image: linear-gradient(to top, rgba($black,.3) 0%, rgba($black,0) 100%);
  // }
}

.timeline {
  position: absolute;
  height: $timeline-height-mobile;
  top: $timeline-padding-mobile;
  width: 100%;

  @include above($tablet) {
    height: $timeline-height;
    top: $timeline-padding;
  }

  @include above(1620px) {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    right: auto !important;
  }

  &__inner {
    position: relative;
    height: 100%;
    background-image: url('../img/ui/timeline-bg.png');
    background-repeat: repeat-x;
    background-position: center;
  }

  &__track {
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    left: -50%;
    background-image: url('../img/ui/timeline-track.svg');
    background-repeat: repeat-x;
    background-position: -4px center;
    opacity: .37;
    position: relative;
    z-index: 0;
  }

  &__epochs {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    left: 16px;
  }
}



.epoch {
  $color: #4A4D58;

  @include fntSize(9, 10, 1.5);
  color: $color;
  //padding: 0 10px;
  padding: 0;
  height: 100%;
  border-left: 1px dashed $color;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include above($tablet){
    @include fntSize(12, 13, 2.5);
  }

  &__date {
    display: inline-block;
    padding-top: 3px;
    position: absolute;
    white-space: nowrap;
    left: 10px;

    @include above($tablet){
      padding-top: 5px;
    }
  }

  &__event-name {
    color: $brown;
    font-weight: 600;
    padding-bottom: 4px;
    position: absolute;
    bottom: 0;
    white-space: nowrap;
    left: 10px;
    opacity: 0;
    transition: opacity 1s;

    @include above($tablet){
      padding-bottom: 8px;
    }
  }

  &.show .epoch__event-name {
    opacity: 1;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    left: -5px;
  }

  &:before {
    border-width: 4px 4.5px 0 4.5px;
    border-color: $color transparent transparent;
    top: 0;
  }

  &:after {
    border-width: 0 4.5px 4px 4.5px;
    border-color: transparent transparent $color transparent;
    bottom: 0;
  }


}


.slider-container {
  @include above($tablet + 1px) {
    height: 100%;
    position: relative;
  }
}


.brands-wrapper--mobile {
  position: relative;
  width: 200%;
  padding: rem(10) 0 rem(40) 0;
  transition: left .6s ease-in-out;

  .brands-slider-overview,
  .brands-slider-wrapper {
    position: relative;
    left: 0;
    width: 50%;
    float: left;
  }

  .brands-slider-wrapper {
    height: auto;
    padding-top: rem(30);
  }
}

.brands-wrapper--desktop {
   min-height: 760px;
   height: 100vh;

  .brands-slider-wrapper {
    height: 100%;
  }

  .slider {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .brands-slider-overview {
    background-image: url('../img/ui/brands-chooser-bg.png');
    background-size: auto auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  .brands-slider-overview,
  .brands-slider-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    transition-property: left, opacity;
    transition-duration: .6s;
    transition-timing-function: ease-in-out;
  }
}

.brands-slider-controls {
  @include clearfix();
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
  outline: 0;

  .btn-ctrl--prev,
  .btn-ctrl--next {

  }

  .btn-ctrl--prev {
    float: left;
  }

  .btn-ctrl--next {
    float: right;
  }

  .brands-slider-overview & {
    left: auto;
  }
}


.hide-brands {
  overflow: hidden;

  .brands-wrapper--desktop {
    .brands-slider-wrapper {
      left: 100%;
      opacity: 0;
      z-index: 0;
    }

    .brands-slider-overview {
      left: 0;
      opacity: 1;
      z-index: 1;
    }
  }

  .brands-wrapper--mobile {
    left: 0;
  }
}

.show-brands {
  overflow: hidden;

  .brands-wrapper--desktop {
    .brands-slider-wrapper {
      left: 0;
      opacity: 1;
      z-index: 1;
    }

    .brands-slider-overview {
      left: -100%;
      opacity: 0;
      z-index: 0;
    }
  }

  .brands-wrapper--mobile {
    left: -100%;
  }
}


.brands-slider-selector {
  position: relative;
  z-index: 9;
}



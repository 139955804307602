.site-header {
  background-color: #182129;
  height: 87px;
  text-align: center;

  @include above($desktop-sm) {
    z-index: 1000;
    // overflow-y: scroll;
  }

  &__inner {
    height: 100%;
    @include above($desktop-sm) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      height: 100%;
      min-height: 700px;
    }
  }
}

.site-header__nav-wrapper,
.site-header__nav-wrapper__inner {
  background-color: #182129;
  // padding-bottom: rem(40);

  @include above($desktop-sm) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding-bottom: 0;
  }

}

.site-header__nav-wrapper {
  overflow: hidden;
  height: 0;
  transition: all.3s ease-in-out;

  // &.is-shown {
  //   max-height: 600px;
  // }

  @include above($desktop-sm) {
    &, &.is-shown {
      height: auto;
    }
  }
}



.site-header__nav-wrapper__inner {

  padding: rem(40) 0;
  border-top: 1px solid rgba($white, .15);


  @include above($desktop-sm) {
    padding: 0;
  border-top: none;
  }
}

.site-header__branding {
  height: 100%;
  @include above($desktop-sm) {
    height: auto;
  }

  .logo {
    display: block;
    width: 60px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    @include above($desktop-sm) {
      width: 114px;
      position: relative;
      top: auto;
      left: auto;
      transform: translate(0,0);
    }

    @include above($desktop-sm) {
      margin-top: 6vh;
    }
  }
}

.site-header__primary-nav {
  @include above($desktop-sm) {
    padding-top: 6vh;
    flex-grow: 1;
  }
}

.site-header__secondary-nav {
  @include above($desktop-sm) {
    padding-bottom: 4vh;
  }
}

.site-header__disclaimer {
  display: none;
  @include above($desktop-sm) {
    padding-bottom: 3vh;
    display: block;
  }
}

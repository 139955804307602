.history {
  &__overlay {
    position: absolute;
    z-index: 3;

    width: 100%;
    height: calc( 100% - 142px);

    top: 0;
    left: 0;

    background-color: rgba($dark-blue, .9);
    cursor: pointer;

    &:hover {
      span {
        letter-spacing: rem(5px);
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 142px;
      bottom: -142px;
      left: 0;

      @include above($tablet) {
        height: 94px;
        bottom: -94px;
      }
    }

    @include above($tablet) {
      height: calc( 100% - 94px);
    }

    .icn--play {
      width: rem(18px);
      height: rem(36px);

      background-size: contain;
      vertical-align: middle;
      margin-right: rem(20px);
    }

    span {
      @include fnt($base-sans-font, 16, 400, 24, 1.2);
      color: $white;
      text-transform: uppercase;
      vertical-align: middle;
      transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);

      @include above($tablet) {
        @include fntSize(24, 30, 1.2)
      }
    }
  }

  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

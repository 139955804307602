.secondary-nav {
  text-align: center;

  &__item {
    @include fnt($base-sans-font, 12, 500, 36, 2.5);
    color: $white;
    text-transform: uppercase;
    position: relative;

    a {
      @include active-dot();
    }

    &.is-active a {
      @include active-dot-active();
    }

    &:hover,
    &:focus,
    &:active {

    }
  }

}
